import React, { useState } from 'react'; 
import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import './Reset_password.css';
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function ResetPassword() {
    const [email, setEmail] = useState(''); 
    const auth = getAuth();
    const navigate = useNavigate();
  
    const triggerResetEmail = async () => {
      try {
        await sendPasswordResetEmail(auth, email);
        toast.success("Password reset link sent to your email");
      } catch (error) {
        toast.error("An error occured sending the link");
      }
    }
   
    return (
      <div className="resetPassword-main">
            <div className="back-button-container" title="Back to SignIn">
        <img 
          src={process.env.PUBLIC_URL + '/images/backBtn.png'}  
          alt="back button" 
          className="top-right-back-button"   
          onClick={() => navigate("/signin")}
          
        />
      </div>
      <div className='reset-form'>
        <h1> Password Reset</h1>  
        <input 
          className='reset-input'
          type="email" 
          value={email} 
          onChange={(e) => setEmail(e.target.value)} 
          placeholder="Enter your email"
          
           />
        <button className="resetBtn" type="button" onClick={triggerResetEmail}>
        Send Reset Link
        </button>
      </div>
      <ToastContainer />
      </div>
    );
    
}

export default ResetPassword;
