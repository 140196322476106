import './Footer.css';
import React, { useEffect } from 'react';

const Footer = () => {
  useEffect(() => {
    const handleScroll = () => {
      const footer = document.querySelector('.footer');

      // Check if footer exists before manipulating it
      if (footer) {
        // Check if the user has scrolled to the bottom of the page
        if (window.innerHeight + window.scrollY >= document.body.offsetHeight - 50) {
          footer.classList.add('visible'); // Add class to slide up
        } else {
          footer.classList.remove('visible'); // Remove class when scrolling up
        }
      }
    };

    // Add scroll event listener
    window.addEventListener('scroll', handleScroll);

    // Cleanup listener on component unmount
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <footer className="footer">
      <div className="footer-container">
        {/* Logo Section */}
        <div className="footer-section logo-section">
          <img
            src="https://fhl.ddb.myftpupload.com/wp-content/uploads/2024/09/cropped-Fits-e-Parcel_Maldives-scaled-1.webp"
            alt="Company Logo"
            className="footer-logo"
          />
        </div>

        {/* Navigation Section */}
        <div className="footer-section navigation-section">
          <h4>Navigation</h4>
          <ul>
            <li><a href="/home">Home</a></li>
            <li><a href="/about-us">About Us</a></li>
            <li><a href="/contact-us">Contact Us</a></li>
          </ul>
        </div>

        {/* Contact Section */}
        <div className="footer-section contact-section">
          <h4>Contact Us</h4>
          <ul>
            <li><i className="fas fa-envelope"></i> <a href="mailto:hello@eparcelsmv.com">hello@eparcelmv.com</a></li>
            <li><i className="fas fa-phone"></i> +960 940 7050</li>
            <li><i className="fas fa-globe"></i> <a href="https://www.eparcelmv.com" target="_blank" rel="noopener noreferrer">www.eparcelmv.com</a></li>
          </ul>
        </div>

        {/* Social Media Section */}
        <div className="footer-section social-section">
          <h4>Get Connected</h4>
          <a href="https://www.facebook.com/eparcel.mv" target="_blank" rel="noopener noreferrer">
            <i className="fab fa-facebook-f"></i>
          </a>
          <a href="https://www.instagram.com/fits_eparcelmv/" target="_blank" rel="noopener noreferrer">
            <i className="fab fa-instagram"></i>
          </a>
        </div>
      </div>
      <div className="footer-bottom">
        <p>Developed by ©FITS IT | 2024</p>
      </div>
    </footer>
  );
};

export default Footer;