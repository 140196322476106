import React from "react";
import { useNavigate } from "react-router-dom";
import { auth } from "../../firebase/Firebase"; // Import Firebase auth
import "./homepage.css";

const Home = () => {
  const navigate = useNavigate();

  const handleDashboardNavigation = () => {
    const user = auth.currentUser; // Get the currently logged-in user
    if (user) {
      // If the user is logged in, navigate to the dashboard
      navigate("/dashboard");
    } else {
      // If the user is not logged in, redirect to the sign-in page
      navigate("/signin");
    }
  };

  return (
    <div>
      {/* Main Header Section */}
      <section className="home-section">
        <div className="content">
          <h1>
            Streamline your <br />
            <span className="highlight">Logistics</span> with <br />
            Eparcel Maldives
          </h1>
          <p>
            Experience unmatched convenience and speed with <br />
            eparcelmv Logistics Service
          </p>
          <div className="buttons">
            <a href="/check-rates" className="btn-check">
              Check Rates
            </a>
            <button
              className="btn-dashboard"
              onClick={handleDashboardNavigation}
            >
              Dashboard
            </button>
          </div>
        </div>
        <div className="image">
          <img src="images/sda.png" alt="Logistics globe" />
        </div>
      </section>

      {/* Services Cards Section */}
      <section className="services-section">
        <h2>Get Anything From Malaysia</h2>
        <p>
          Unlocking New Horizons: Faster, More Cost-Effective Shipping from
          Malaysia
        </p>
        <div className="card-container">
          <div className="card_1">
            <img
              src="images/1759326.gif"
              alt="Assisted Shopping"
              className="card-image"
            />
            <h3>Assisted Shopping</h3>
            <p>
              Let us buy for you! Send us URL/Links of your products to
              purchase, from any Online stores in Malaysia.
            </p>
            <a href="#" className="read-more">
              READ MORE
            </a>
          </div>
          <div className="card_2">
            <img
              src="images/1759326.gif"
              alt="Shop Online"
              className="card-image"
            />
            <h3>Shop Online</h3>
            <p>
              Buy from any Online Stores in Malaysia, ship your shopping package
              to RedBox address in Malaysia.
            </p>
            <a href="#" className="read-more">
              READ MORE
            </a>
          </div>
          <div className="card_3">
            <img
              src="images/1759326.gif"
              alt="Drop-off Packages"
              className="card-image"
            />
            <h3>Drop-off Packages</h3>
            <p>
              Ask your friends and family in Malaysia to buy for you in-store
              and deliver it to RedBox address.
            </p>
            <a href="#" className="read-more">
              READ MORE
            </a>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Home;
