import React, { useState } from 'react';
import './check_rates.css';
import { useNavigate } from 'react-router-dom'; // Import the navigate hook

//testing
const CheckRates = () => {
  const [fromCountry, setFromCountry] = useState('Canada');
  const [toCountry, setToCountry] = useState('Maldives');
  const [weightType, setWeightType] = useState('Actual Weight'); // To toggle between actual and volumetric weight
  const [weight, setWeight] = useState(''); // For actual weight
  const [length, setLength] = useState(''); // For volumetric length
  const [width, setWidth] = useState(''); // For volumetric width
  const [height, setHeight] = useState(''); // For volumetric height
  const [volumetricWeight, setVolumetricWeight] = useState(0); // To store the calculated volumetric weight
  const [priceMVR, setPriceMVR] = useState(0);
  const [priceAED, setPriceAED] = useState(null);
  const [sheetRate, setSheetRate] = useState(null); // For Google Sheet Rate
  const [regularRates, setRegularRates] = useState([]); // Store dynamic regular rates per kg

  const navigate = useNavigate(); // Hook for navigation
  const handleGoBack = () => {
    navigate(-1); // Go back to the previous page in history
  };

  const API_KEY = 'AIzaSyCdDHnrqB4oh00AZLPFT9Cu2iezsAqhB-o'; // Your Google Sheets API key
  const SHEET_ID = '1Vh7hJnViAnV4B5jhXGyLoq23XKSGiI0sh4SFwntaoxA'; // Your Google Sheet ID
  const SHEET_RANGE = 'Sheet1'; // Range in your Google Sheet

  const countryFlags = {
    Malaysia: 'https://flagcdn.com/w320/my.png',
    Singapore: 'https://flagcdn.com/w320/sg.png',
    India: 'https://flagcdn.com/w320/in.png',
    UAE: 'https://flagcdn.com/w320/ae.png',
    USA: 'https://flagcdn.com/w320/us.png',
    Australia: 'https://flagcdn.com/w320/au.png',
    Canada: 'https://flagcdn.com/w320/ca.png',
    China: 'https://flagcdn.com/w320/cn.png',
    Japan: 'https://flagcdn.com/w320/jp.png',
    UK: 'https://flagcdn.com/w320/gb.png',
    Maldives: 'https://flagcdn.com/w320/mv.png'
  };

  const handleFromCountryChange = (event) => {
    setFromCountry(event.target.value);
  };

  const handleToCountryChange = (event) => {
    setToCountry(event.target.value);
  };

  const handleWeightTypeChange = (type) => {
    setWeightType(type);
  };

  const handleWeightChange = (event) => {
    setWeight(event.target.value);
  };

  const roundUpWeight = (weight) => {
    const weightBands = [
      0.5, 1, 1.5, 2, 2.5, 3, 3.5, 4, 4.5, 5, 5.5, 6, 6.5, 7, 7.5, 8, 8.5, 9, 9.5, 10,
      10.5, 11, 11.5, 12, 12.5, 13, 13.5, 14, 14.5, 15, 15.5, 16, 16.5, 17, 17.5, 18,
      18.5, 19, 19.5, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35,
      36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55,
      56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70,
    ];

    // Use find to get the first weightBand greater than or equal to the weight
    return weightBands.find((band) => weight <= band) || weightBands[weightBands.length - 1];
  };

  // Volumetric Weight Calculation (L x W x H) / 5000 for example
  const calculateVolumetricWeight = () => {
    if (length && width && height) {
      const volumetric = (length * width * height) / 5000;
      return parseFloat(volumetric.toFixed(2));
    }
    return 0;
  };

  const calculatePrice = async () => {
    let baseWeight = parseFloat(weight);
    let finalWeight = baseWeight;
  
    if (weightType === 'Volumetric Weight') {
      finalWeight = calculateVolumetricWeight();
      setVolumetricWeight(finalWeight);
    }
  
    finalWeight = roundUpWeight(finalWeight);
  
    // If weight is above 70, use the special rate for 70+
    if (baseWeight > 70) {
      await fetchGoogleSheetRateAbove70(baseWeight); // Pass the actual baseWeight for calculation
    } else {
      await fetchGoogleSheetRate(finalWeight);
    }
  };
  
  const fetchGoogleSheetRateAbove70 = async (actualWeight) => {
    const url = `https://sheets.googleapis.com/v4/spreadsheets/${SHEET_ID}/values/${SHEET_RANGE}?key=${API_KEY}`;
    try {
      const response = await fetch(url);
      const data = await response.json();
      const rows = data.values;
  
      // Find the row for 70+ rate
      const matchingRow = rows.find(
        (row) => row[1] === '70 +' && row[3] === fromCountry && row[0] === 'Non- Document'
      );
  
      if (matchingRow) {
        const ratePerKg = parseFloat(matchingRow[2]); // Get the rate for 70+
        const calculatedPrice = ratePerKg * actualWeight; // Calculate the price based on the actual weight
  
        // Extract MVR and AED rates from the corresponding columns
        const mvrRate = parseFloat(matchingRow[5]); 
        const aedRate = parseFloat(matchingRow[6]); 
  
        // Set the rates for MVR and AED
        setSheetRate(calculatedPrice); // Set the calculated price for display
        setPriceMVR(calculatedPrice * mvrRate); // MVR conversion using the sheet rate
        setPriceAED(calculatedPrice * aedRate); // AED conversion using the sheet rate
      } else {
        setSheetRate('Rate not available for this weight and type.');
      }
    } catch (error) {
      console.error('Error fetching Google Sheets data:', error);
    }
  };
  
  const fetchGoogleSheetRate = async (roundedWeight) => {
    const url = `https://sheets.googleapis.com/v4/spreadsheets/${SHEET_ID}/values/${SHEET_RANGE}?key=${API_KEY}`;
    try {
      const response = await fetch(url);
      const data = await response.json();
      const rows = data.values;
  
      // Filter rows to match the rounded weight, country, and 'Non-Document' type
      const matchingRow = rows.find(
        (row) => parseFloat(row[1]) === roundedWeight && row[3] === fromCountry && row[0] === 'Non- Document'
      );
  
      if (matchingRow) {
        const ratePerKg = parseFloat(matchingRow[2]); // Get the rate for the given weight
        const mvrRate = parseFloat(matchingRow[5]); 
        const aedRate = parseFloat(matchingRow[6]); 
        
        const calculatedPrice = ratePerKg ;
        const calculatedPriceMVR = mvrRate ;
        const calculatedPriceAED = aedRate ;  // Calculate the price based on the weight
  
        // Extract MVR and AED rates from the corresponding columns
   
  
        // Set the rates for MVR and AED
        setSheetRate(calculatedPrice); // Set the calculated price for display
        setPriceMVR(calculatedPriceMVR); // MVR conversion using the sheet rate
        setPriceAED(calculatedPriceAED); // AED conversion using the sheet rate
  
        updateRegularRates(rows, fromCountry);
      } else {
        setSheetRate('Rate not available for this weight and type.');
        setRegularRates([]); // Clear rates if no match
      }
    } catch (error) {
      console.error('Error fetching Google Sheets data:', error);
    }
  };
  
  const updateRegularRates = (rows, country) => {
    // Fetch rates from Google Sheet rows for different weight bands, country, and "Non-Document" type
    const weightBands = [2, 5, 10, 45]; // Adjust the weight bands as per your needs
    const rates = weightBands.map((band) => {
      const matchingRow = rows.find(
        (row) => parseFloat(row[1]) === band && row[3] === country && row[0] === 'Non- Document'
      );
      return matchingRow ? parseFloat(matchingRow[2]) : 'N/A';
    });

    setRegularRates(rates);
  };

  
  return (
  <div className="check-rates-container-main">
    <div className="back-button-container" title="Go Back">
        <img 
          src={process.env.PUBLIC_URL + '/images/backBtn.png'}  
          alt="back button" 
          className="top-right-back-button"   
          onClick={handleGoBack}
          
        />
      </div>
    <div className="check-rates-container">
      <div className="check-rates-header">
        <h1 className="h1_check">Calculate Rates</h1>
      </div>

      <div className="rate-form">
        {/* Country Selection */}
        <div className="form-group">
          <label htmlFor="fromCountry">FROM</label>
          <div className="country-select-rates " >
            <img src={countryFlags[fromCountry]} alt={fromCountry} className="flag" />
            <select id="fromCountry" value={fromCountry} onChange={handleFromCountryChange} 
                style={{color: 'white', fontWeight:'500', backgroundColor:'#0056b3',borderRadius:'10px', 
                          textAlign:'center',   fontFamily:'Segoe UI, Tahoma, Geneva, Verdana, sans-serif'}}>
              <option value="UK">UK</option>
              <option value="Japan">Japan</option>
              <option value="China">China</option>
              <option value="Canada">Canada</option>
              <option value="Australia">Australia</option>
              <option value="USA">USA</option>
              <option value="UAE">UAE</option>
              <option value="India">India</option>
              <option value="Singapore">Singapore</option>
              <option value="Malaysia">Malaysia</option>
            </select>
          </div>
        </div>

        {/* Arrow Icon */}
        <div className="arrow-down">
          <lord-icon
            src="https://cdn.lordicon.com/vduvxizq.json"
            trigger="loop"
            delay="1000"
            stroke="bold"
            colors="primary:#0f2853" 
            style={{ width: '55px', height: '55px' }}>
          </lord-icon>
        </div> 

        <div className="form-group">
          <label htmlFor="toCountry">TO</label>
          <div className="country-select-rates">
            <img src={countryFlags[toCountry]} alt={toCountry} className="flag" />
            <select id="toCountry" value={toCountry} onChange={handleToCountryChange} style={{color: 'white', fontWeight:'500', 
                            fontFamily:'Segoe UI, Tahoma, Geneva, Verdana, sans-serif'}}>
              <option value="Maldives" >Maldives</option>
            </select>
          </div>
        </div>
      </div>

      {/* Weight Section */}
      <div className="weight-section">
      <p className="info-message-weight">
            Weight will be determined by either Actual or Volumetric Weight (VW) or whichever is higher.
            Confirmed freight charges will be emailed once we receive the package/items.
      </p>

        <div className="weight-tabs">
          <span
            className={`tab ${weightType === 'Actual Weight' ? 'active-tab' : ''}`}
            onClick={() => handleWeightTypeChange('Actual Weight')}
          >
            Actual Weight
          </span>
          <span
            className={`tab ${weightType === 'Volumetric Weight' ? 'active-tab' : ''}`}
            onClick={() => handleWeightTypeChange('Volumetric Weight')}
          >
            Volumetric Weight
          </span>
        </div>

        {weightType === 'Actual Weight' ? (
          <div className="weight-input-section">
            <label>Weight(kg)&nbsp;&nbsp;&nbsp;</label>
            <input
              type="text"
              value={weight}
              onChange={handleWeightChange}
              placeholder="Enter weight"
              className="weight"
            />
          </div>
        ) : (
          <div className="volumetric-input-section">
            <div className="input-row">
              <label>Length (cm)</label>
              <input
                type="text"
                value={length}
                onChange={(e) => setLength(e.target.value)}
                placeholder="Length"
              />
            </div>
            <div className="input-row">
              <label>Width (cm)</label>
              <input
                type="text"
                value={width}
                onChange={(e) => setWidth(e.target.value)}
                placeholder="Width"
              />
            </div>
            <div className="input-row">
              <label>Height (cm)</label>
              <input
                type="text"
                value={height}
                onChange={(e) => setHeight(e.target.value)}
                placeholder="Height"
              />
            </div>
            <div className="volumetric-weight-result">
              <label>Volumetric Weight(KG)&nbsp;&nbsp;&nbsp;</label>
              <input type="text" value={volumetricWeight} readOnly className="volumetric" />
            </div>
          </div>
        )}

        <button onClick={calculatePrice} className="calculate-button">
          Calculate
        </button>

        {/* Price Display */}
        <div className="price-section">
          <div className="price-item">  
            <img src={countryFlags['USA']} alt="USD" className="flag" /> USD {sheetRate}
          </div>
          <div className="price-item">
            <img src={countryFlags['Maldives']} alt="MVR" className="flag" /> MVR {priceMVR}
          </div>
          <div className="price-item">
            <img src={countryFlags['UAE']} alt="AED" className="flag" /> AED {priceAED}
          </div>
         
        </div>
        <div className='surcharge'>
        <p>The above rate excludes fuel surcharge, which will be added to your final bill.
        <a href="https://www.ups.com/lk/en/support/shipping-support/shipping-costs-rates/fuel-surcharges.page" 
            target="_blank" rel="noopener noreferrer"> Click here</a> for rate details.</p>
        </div>
      </div>

      {/* Shipping Rate Table */}
      <div className="rate-table-section">
        <table className="rate-table">
          <thead>
            <tr>
              <th>WEIGHT BANDS</th>
              <th>REGULAR (USD) PER KG</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>1+ to 2 kg</td>
              <td>{regularRates[0] || '8.70'}</td>
            </tr>
            <tr>
              <td>2+ to 5 kg</td>
              <td>{regularRates[1] || '8.50'}</td>
            </tr>
            <tr>
              <td>5+ to 10 kg</td>
              <td>{regularRates[2] || '8.25'}</td>
            </tr>
            <tr>
              <td>10+ to 45 kg</td>
              <td>{regularRates[3] || '7.90'}</td>
            </tr>
            <tr>
              <td>45+ kg</td>
              <td>{regularRates[4] || '7.50'}</td>
            </tr>
          </tbody>
        </table>
        <div className="table-note">
          <i className="fas fa-info-circle"></i> &nbsp; Select your origin country, enter the package weight, and click 'Calculate' to receive the applicable rates for your shipment.
        </div>
      </div>
    </div>
    </div>
  );
};

export default CheckRates;
