import React, { useState, useEffect } from "react";
import "./Dashboard.css";
import { useNavigate } from "react-router-dom";
import { auth, db } from "../../firebase/Firebase";
import {
  doc,
  getDoc,
  collection,
  addDoc,
  query,
  where,
  onSnapshot,
  updateDoc,
  Timestamp,
  getDocs,
} from "firebase/firestore";
import { storage } from "../../firebase/Firebase"; // Import storage from Firebase config
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage"; // Import necessary methods for file upload
import Footer from "../../components/footer/Footer";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ShipYourItem from "../../components/Shiping_popup/Ship_your_Item.jsx";






const Dashboard = () => {
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState(""); // For tab toggling
  
  const [selectedStatus, setSelectedStatus] = useState("Received");
  const [availableItems, setAvailableItems] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isShoppingRequestModalOpen, setIsShoppingRequestModalOpen] = useState(false);
  const [selectedRequest, setSelectedRequest] = useState(null);
  const [items, setItems] = useState([{ id: 1, url: "", Description: "" }]);
  const [userName, setUserName] = useState("");
  const [userId, setUserId] = useState("");
  const [weight, setWeight] = useState("");
  const [length, setLength] = useState("");
  const [width, setWidth] = useState("");
  const [height, setHeight] = useState("");
  const [Volumetric, setVolumetric] = useState("");
  const [address, setAddress] = useState({});
  const [shoppingRequests, setShoppingRequests] = useState([]);
  const [shoppingRequestName, setShoppingRequestName] = useState("");
  const [agreedToTerms, setAgreedToTerms] = useState(false);
  const [bankDetails, setBankDetails] = useState([]);
  const [receiptFile, setReceiptFile] = useState(null);
  const [progress, setProgress] = useState(0); // Upload Progress Bar
  const [selectedRequestIds, setSelectedRequestIds] = useState([]);
  const [receivedPackages, setReceivedPackages] = useState([]); // State for received packages
  const [loading, setLoading] = useState(false);
  const [showPopup, setShowPopup] = useState(false); // State to control popup visibility
  const [searchTerm, setSearchTerm] = useState(""); // State for search input
  const [selectedStatusShop, setSelectedStatusShop] = useState('');  // State to track the selected status
  const [shippingItems, setShippingItems] = useState([]);


// Filter requests based on the search term
const filteredRequests = shoppingRequests.filter((request) =>
  request.Request_name.toLowerCase().includes(searchTerm.toLowerCase())
);

const [currentPage, setCurrentPage] = useState(1); // Track the current page
const cardsPerPage = 6; // Display 6 cards per page

useEffect(() => {
  setCurrentPage(1); // Reset to the first page when search term or status changes
}, [searchTerm, selectedStatusShop]);

// Filter and sort requests based on search and status filters
const filteredAndSortedRequests = filteredRequests
  .filter((request) => selectedStatusShop === "" || request.Status === selectedStatusShop)
  .sort((a, b) => b.createdAt.seconds - a.createdAt.seconds);

// Calculate total pages and displayed item range
const totalPages = Math.ceil(filteredAndSortedRequests.length / cardsPerPage);
const startIndex = (currentPage - 1) * cardsPerPage + 1;
const endIndex = Math.min(currentPage * cardsPerPage, filteredAndSortedRequests.length);

// Slice the filtered requests to display only the current page
const currentRequests = filteredAndSortedRequests.slice(
  (currentPage - 1) * cardsPerPage,
  currentPage * cardsPerPage
);


const generatePageNumbers = (currentPage, totalPages) => {
  const pageNumbers = [];

  // Always show the first page
  if (currentPage > 3) {
    pageNumbers.push(1);
    if (currentPage > 4) {
      pageNumbers.push("...");
    }
  }

  // Pages around the current page
  for (let i = Math.max(1, currentPage - 2); i <= Math.min(totalPages, currentPage + 2); i++) {
    pageNumbers.push(i);
  }

  // Always show the last page
  if (currentPage < totalPages - 2) {
    if (currentPage < totalPages - 3) {
      pageNumbers.push("...");
    }
    pageNumbers.push(totalPages);
  }

  return pageNumbers;
};
const pageNumbers = generatePageNumbers(currentPage, totalPages);


  // Fetch bank details when the component mounts
  useEffect(() => {
    const fetchBankDetails = async () => {
      const bankCollectionRef = collection(db, "bank_Details");
      const bankSnapshot = await getDocs(bankCollectionRef);
      const banks = bankSnapshot.docs.map((doc) => doc.data());
      setBankDetails(banks);
    };

    fetchBankDetails();
  }, []);

// State to track selected warehouse location
const [selectedLocation, setSelectedLocation] = useState('');

// Fetch Received Packages filtered by Customer_ID and Warehouse_Location
useEffect(() => {
  const fetchReceivedPackages = async () => {
    if (userId) { 
      let q = query(collection(db, 'Received_packages'), where('Customer_ID', '==', userId));

      // Apply Warehouse_Location filter only if selected
      if (selectedLocation) {
        q = query(q, where('Warehouse_Location', '==', selectedLocation));
      }

      const unsubscribe = onSnapshot(q, (snapshot) => {
        const packages = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setReceivedPackages(packages);

        // Calculate the package counts by location
        const counts = calculateLocationCounts(packages);
        setLocationCounts(counts); // Update the locationCounts state
      });

     
      return () => unsubscribe(); 
    }
  };

  fetchReceivedPackages();
}, [userId, selectedLocation]); // Re-run when userId or selectedLocation changes





  const handleFooterClick = (item) => {
    if (activeTab === item) {
      setActiveTab(""); // Close the tab if clicked again
    } else {
      setActiveTab(item); // Open the clicked tab
    }
  };

  const toggleBodyScrolling = (disableScroll) => {
    document.body.style.overflow = disableScroll ? "hidden" : "auto";
  };

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
    toggleBodyScrolling(!isModalOpen);
  };

  const toggleEditModal = () => {
    setIsEditModalOpen(!isEditModalOpen);
    toggleBodyScrolling(!isEditModalOpen);
  };

  const toggleShoppingRequestModal = () => {
   
    setItems([{ id: 1, url: "", Description: "" }]);
    setIsShoppingRequestModalOpen(!isShoppingRequestModalOpen);
    toggleBodyScrolling(!isShoppingRequestModalOpen);
  };
  

  const addItem = () => {
    setItems([...items, { id: items.length + 1, url: "", Description: "" }]);
  };

  const handleItemChange = (index, field, value) => {
    const updatedItems = items.map((item, i) =>
      i === index ? { ...item, [field]: value } : item
    );
    setItems(updatedItems);
  };

  const calculateTotalPrice = (unitPrice, qty) => {
    return (unitPrice * qty).toFixed(2); 
  };
  

  const deleteItem = (id) => {
    setItems(items.filter((item) => item.id !== id));
  };

  const handleSubmitRequestToPurchase = async () => {
    // Check if all items fields are filled
    const allFieldsFilled = items.every(item => item.url !== '' && item.Description !== '');
    if (!allFieldsFilled) {
      toast.error("Please fill in all required fields");
      return;
    }
  
    // Check if terms are agreed
    if (!agreedToTerms) {
      toast.warning("Please agree to the terms and conditions");
      return;
    }
  
    // Call the existing request function if validation passes
    try {
      setLoading(true); // Show loader
  
      const user = auth.currentUser;
      if (user) {
        const userDocRef = doc(db, "users", user.uid);
        const userDocSnapshot = await getDoc(userDocRef);
  
        if (userDocSnapshot.exists()) {
          const userData = userDocSnapshot.data();
          const userId = userData.User_ID;
  
          const shoppingCollectionRef = collection(db, "shopping_Requests");
          const createdAt = Timestamp.now();
  
          await addDoc(shoppingCollectionRef, {
            Request_id: `REQ_${new Date().getTime()}`,
            customer_id: userId,
            Service_type: "Assisted Shopping",
            Status: "Pending",
            Weight: Number(weight),
            Length: Number(length),
            Width: Number(width),
            Height: Number(height),
            Volumetric_Weight: Number(Volumetric),
            Request_name: shoppingRequestName,
            product_reference_url: items.map((item) => item.url),
            Description: items.map((item) => item.Description),
            createdAt,
          });
          
          setLoading(false); // Hide loader after success
          toast.success("Shopping request successfully submitted!", {
            autoClose: 2000, // Auto close after 2 seconds
            closeOnClick: true,
            pauseOnHover: true,
          });
  
          // Close modal after a brief delay for toast to show
          setTimeout(() => {
            toggleShoppingRequestModal();
          }, 2000);
        } else {
          console.error("User document does not exist.");
          toast.error("Could not retrieve user data. Please try again.");
          setLoading(false); // Hide loader if there's an error
        }
      } else {
        navigate("/signin");
        setLoading(false); // Hide loader if user is not signed in
      }
    } catch (error) {
      setLoading(false); // Hide loader in case of error
      console.error("Error submitting shopping request:", error);
      toast.error("Error submitting shopping request. Please try again.");
    }
  };
  

  const handleEditRequest = () => {
    if (selectedRequest) {
      setItems(
        selectedRequest.product_reference_url.map((url, index) => ({
          id: index + 1,
          url,
          Description: selectedRequest.Description[index],
        }))
      );
      toggleEditModal();
    }
  };

  

  const handleSaveChanges = async () => {
    if (selectedRequest) {
      try {
        const requestDocRef = doc(db, "shopping_Requests", selectedRequest.id);
        await updateDoc(requestDocRef, {
          product_reference_url: items.map((item) => item.url),
          Description: items.map((item) => item.Description),
          Status: "Pending", // Update the Status field to "Pending"
        });
  
        setSelectedRequest({
          ...selectedRequest,
          product_reference_url: items.map((item) => item.url),
          Description: items.map((item) => item.Description),
          Status: "Pending", // Update the local state for Status as well
        });
  
        alert("Purchase request updated successfully!");
        toggleEditModal();
      } catch (error) {
        console.error("Error updating purchase request:", error);
      }
    }
  };
  

  
// Packages popup handle
const [isPackagesPopupOpen, setIsPackagesPopupOpen] = useState(false);
// Function to handle opening the Packages popup
const handlePackagesButtonClick = () => {
  setIsPackagesPopupOpen(true);
};

// Function to handle closing the Packages popup
const handleClosePackagesPopup = () => {
  setIsPackagesPopupOpen(false);
};


  // Function to handle item selection
const handleItemSelect = (requestId) => {
  setSelectedRequestIds((prevIds) =>
    prevIds.includes(requestId)
      ? prevIds.filter((id) => id !== requestId) // Deselect item if already selected
      : [...prevIds, requestId] // Select item if not already selected
  );
};

// Function to handle "Request to Ship Your Items" button click
const handleRequestToShip = async () => {
  console.log(selectedRequestIds);
  if (selectedRequestIds.length === 0) {
    toast.warning("Please select at least one item to ship.");
    return;
  }

  try {
    const shippingCollectionRef = collection(db, "Shipping_items");
    const createdAt = Timestamp.now();
    
    // Add a new document to "Shipping_items" collection with the selected Request IDs
    await addDoc(shippingCollectionRef, {
      request_ids: selectedRequestIds,
      createdAt,
    });

    
    toast.success("Request to ship your items has been successfully submitted!");
    setSelectedRequestIds([]); // Clear selected items
  } catch (error) {
    console.error("Error adding document to Shipping_items:", error);
  }
};

  // Fetch user data
  useEffect(() => {
    const fetchUserData = async () => {
      const user = auth.currentUser;
      if (user) {
        const userDocRef = doc(db, "users", user.uid);
        const userSnapshot = await getDoc(userDocRef);

        if (userSnapshot.exists()) {
          const userData = userSnapshot.data();
          setUserName(userData["User Name"]);
          setUserId(userData["User_ID"]);
          setAddress(userData.Address);
        } else {
          console.log("No such document!");
        }
      } else {
        navigate("/signin");
      }
    };

    fetchUserData();
  }, [navigate]);



  // Fetch packages tab
  useEffect(() => {
    const fetchShippingItems = async () => {
      try {
        const shippingCollectionRef = collection(db, 'Shipping_items');
        const q = query(shippingCollectionRef, where('Customer_ID', '==', userId));
        const querySnapshot = await getDocs(q);
  
        const items = querySnapshot.docs
          .map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }))
          .filter((item) => item.Status !== 'Complete'); // Filter Status != "Ready to Ship"
  
        setShippingItems(items);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching shipping items:', error);
        setLoading(false);
      }
    };
  
    if (userId) {
      fetchShippingItems();
    }
  }, [userId]);
  
  
  

  // Fetch shopping requests ****
  useEffect(() => {
    const user = auth.currentUser;
    if (user) {
      const shoppingRequestsRef = collection(db, "shopping_Requests");
      const q = query(shoppingRequestsRef, where("customer_id", "==", userId));

      const unsubscribe = onSnapshot(q, (querySnapshot) => {
        const requests = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setShoppingRequests(requests);
      });

      return () => unsubscribe();
    } else {
      navigate("/signin");
    }
  }, [navigate, userId]);

  // Handle receipt upload
  const handleReceiptUpload = () => {
    if (!receiptFile) {
      alert("Please select a file to upload.");
      return;
    }
  
    const storageRef = ref(
      storage,
      `paymentReceipts/${selectedRequest.Request_id}/${receiptFile.name}`
    );
    
    const uploadTask = uploadBytesResumable(storageRef, receiptFile);
    setProgress(0); //resetting progress to 0 before starting new upload
  
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        // Get upload progress as percentage
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        setProgress(progress); // Update progress state
        console.log(`Upload is ${progress}% done`);
      },
      (error) => {
        console.error("Error uploading file:", error);
        alert("Error uploading file. Please try again.");
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          const requestDocRef = doc(db, "shopping_Requests", selectedRequest.id);
          // Update both the receipt URL and the status to "Payment Verification Pending"
          updateDoc(requestDocRef, {
            paymentReceiptURL: downloadURL,
            Status: "Payment Verification Pending",
          })
            .then(() => {
              console.log("Receipt uploaded successfully");
              toast.success(
                "Receipt uploaded successfully and status updated to Payment Verification Pending!"
              );
            })
            .catch((error) => {
              console.error("Error updating document:", error);
              toast.error("Error saving receipt URL and updating status to the database.");
            });
        });
      }
    );
  };
  
  const handleViewDetails = (request) => {
    setSelectedRequest(request);
    toggleModal();
  };

  const calculateLocationCounts = (packages) => {
    const counts = {};

    packages.forEach((pkg) => {
      if (pkg.Status !== "Ready to Ship" && (selectedStatusShop === "" || pkg.Status === selectedStatusShop)) { 
        const location = pkg.Warehouse_Location;
        if (location) {
          if (!counts[location]) {
            counts[location] = 1;
          } else {
            counts[location] += 1;
          }
        }
      }
    });
  
    return counts;
};

  const [locationCounts, setLocationCounts] = useState({})
 
  // Calculate total packages by location (this runs only when receivedPackages changes)
  useEffect(() => {
    if (receivedPackages.length > 0) {
      const counts = calculateLocationCounts(receivedPackages); // Calculate with filtering
      setLocationCounts(counts); // Store filtered package counts by location
    }
  }, [receivedPackages, selectedStatusShop]);
  
   // Mockup for fetching packages data
   useEffect(() => {
    // Simulate fetching packages (replace with your actual fetching logic)
    const mockPackages = [
      { Warehouse_Location: 'USA' },
      { Warehouse_Location: 'UAE' },
      { Warehouse_Location: 'UK' },
      { Warehouse_Location: 'Canada' },
      { Warehouse_Location: 'India' },
      { Warehouse_Location: 'Australia' },
      { Warehouse_Location: 'China' },
      { Warehouse_Location: 'Malaysia' },
      { Warehouse_Location: 'Japan' },
      { Warehouse_Location: 'Singapore' },
    ];
    setReceivedPackages(mockPackages);
  }, []);

  // Filter the received packages based on selected location (if any)
const filteredPackages = selectedLocation
? receivedPackages.filter(pkg => pkg.Warehouse_Location === selectedLocation)
: receivedPackages;
  
  // Function to add 'https://' if it's not present
const getUrlWithProtocol = (url) => {
  // Check if the URL already starts with 'http://' or 'https://'
  if (!/^https?:\/\//i.test(url)) {
    return `https://${url}`; // Prepend 'https://'
  }
  return url;
};
  
  
  return (
    <div className="dashboard-container-main">
  <div className="dashboard-container">
    <div className="welcome-section">
      <div className="welcomehead-container">
        <h1>Dashboard</h1>
      </div>
      <p>Eparcel Maldives Address (User ID included)</p>
      <div className="address-card-dashbaord">
        <p>
          <span style={{ fontSize: "25px", fontWeight: "700" }}>{userName}</span>
          <br />
          User ID: {userId} <br />
          {address.addressLine1} <br />
          {address.addressLine2} <br />
          {address.addressLine3} <br />
          {address.addressLine4} <br />
          {address.addressLine5} <br />
          {address.country}
        </p>
      </div>
      <button className="shopping-request-btn" onClick={toggleShoppingRequestModal}>
        Assisted Shopping Request
      </button>
      <div className="packages_buttoon">
        <button className="footer-item" onClick={() => handleFooterClick("items")}>
          Items 
        </button>
        <button className="footer-item" onClick={() => handleFooterClick("packages")}>
          Packages
        </button>
        <button className="footer-item" onClick={() => handleFooterClick("shopping")}>
          Shopping
        </button>
        <button className="footer-item" onClick={() => handleFooterClick("orders")}>
          Orders
        </button>
      </div>
    </div>

    {activeTab === "items" && (
      <div className="items-tab-container">

        {/* Location-wise package counts */}
        <div className="location-counts-container">
        {Object.keys(locationCounts).length > 0 ? (
          Object.entries(locationCounts).map(([location, count]) => (
            <div key={location}>
              <p className="locp">{location}: {count} packages</p>
            </div>
          ))
        ) : (
          <p>No packages available.</p>
        )}
      </div>



          {/* Filter by Warehouse Location */}
          <div className="filter-section">
        <label>Ship From</label>
        <select 
          value={selectedLocation} 
          onChange={(e) => {
            setSelectedLocation(e.target.value);
            setShowPopup(false); // Hide the popup when the location changes
          }}
        >
          <option 
            style={{color:'#8F9191'}}
            value="" disabled
          >
            Select Location
          </option> 
          <option value="Australia">Australia</option>
          <option value="Canada">Canada</option>
          <option value="China">China</option>
          <option value="India">India</option>
          <option value="Japan">Japan</option>
          <option value="Malaysia">Malaysia</option>
          <option value="Singapore">Singapore</option>
          <option value="UAE">UAE</option>
          <option value="UK">UK</option>
          <option value="USA">USA</option>
        </select>
      </div>


    <div className="items-display">
      {selectedLocation && receivedPackages.length === 0 ? (
        
        <div className="info-error-items">
        <lord-icon
          src="https://cdn.lordicon.com/msyeyaka.json"
          trigger="loop"
          delay="1000"
          stroke="bold"
          colors="primary:#a1ddfd,secondary:#a1ddfd"
          style={{ width: "85px", height: "85px" }}
        ></lord-icon>
        <p>No items available for the selected location.</p>
        
      </div>
        
      ) : (
        <ul>
          {receivedPackages
            .filter(
              (item) =>
                item.Warehouse_Location === selectedLocation &&
                item.Status != "Ready to Ship"
            )
            .map((item) => (
              <li
                key={item.id}
                onClick={() => handleItemSelect(item.Order_ID)} // Handle item selection
                style={{
                  cursor: "pointer",
                  backgroundColor: selectedRequestIds.includes(item.Order_ID)
                    ? "#b2ebf2"
                    : "#e0f7fa", // Highlight selected items
                }}
              >
                <div>Tracking Number: {item.Tracking_Number} </div>
                <div>Warehouse Location: {item.Warehouse_Location} </div>
                <div>Order Number: {item.Order_ID}</div>
                <div>Registered on:  {new Date(item.createdAt.seconds * 1000).toLocaleString()}</div>
              </li>
            ))}
        </ul>
      )}
    </div>

 
    <div>
      {/* Button to send selected items to Firestore and open popup */}
      <button
            className="ship-items-btn"
            onClick={() => {
              // Check if any items are selected
              if (selectedRequestIds.length > 0) {
                // If items are selected, show the popup
                setShowPopup(true);
              } else {
                // If no items are selected, trigger the warning and do not show the popup
                handleRequestToShip();
              }
            }}
            disabled={!selectedLocation}
          >
          Request to Ship Your Items
      </button>


      {/* Show the ShipYourItem popup if the button is clicked */}
      {showPopup && 
      <ShipYourItem 
        onClose={() => setShowPopup(false)} 
        selectedRequestIds={selectedRequestIds}
        />} {/* Close the popup when finished */}
    </div>
  </div>
)}


{activeTab === "packages" && (
  <div className="packages-popup-overlay">
  <div className="packages-popup-content">
    
    <h2>Your Packages</h2>

    {loading ? (
      <div className="loader">Loading...</div>
    ) : shippingItems.length === 0 ? (
      <p>No packages items found!</p>
    ) : (
    <div className="shipping-items-table-flex">
      <table className="shipping-items-table">
          <thead>
            <tr>
              <th>Order Number</th>
              <th>Billable Weight</th>
              <th>Warehouse Location</th>
              <th>Created At</th>
            </tr>
          </thead>
          <tbody>
            {shippingItems.map((item) => (
              <tr key={item.id}>
                <td>{item.request_ids}</td>
                <td>{item.total_billable_weight}</td>
                <td>{item.warehouse_location}</td>
                <td>{new Date(item.createdAt.seconds * 1000).toLocaleString()}</td>
              </tr>
            ))}
          </tbody>
    </table>
    </div>
    )}
  </div>
</div>
)}



{activeTab === "shopping" && (
  <div className="shopping-requests-container">
    <div className="shopping-requests-section">
      <h2>Your Shopping Requests</h2>
      
      {/* Search bar and filter */}
      <div className="search-bar-container">
        <div className="search-bar">
          <input
            type="text"
            placeholder="Search by Purchase Name..."
            value={searchTerm} 
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>

        {/* Dropdown for filtering by status */}
          <div className="status-filter">
            <select
              value={selectedStatusShop}
              onChange={(e) => setSelectedStatusShop(e.target.value)}
            >
              <option value="">All Packages</option>
              <option value="Pending">Pending</option>
              <option value="Agent Review Ongoing">Agent Review Ongoing</option>
              <option value="Awaiting Item Payment">Awaiting Item Payment</option>
              <option value="Processing">Processing</option>
            </select>
          </div>
        </div>

      {/* Range Info */}
      <div className="range-info">
        {startIndex}-{endIndex} of {filteredAndSortedRequests.length}
      </div>

      {/* Display filtered shopping requests */}
      {currentRequests.length === 0 ? (
        <div className="info-error">
          <lord-icon
            src="https://cdn.lordicon.com/msyeyaka.json"
            trigger="loop"
            delay="1000"
            stroke="bold"
            colors="primary:#112151,secondary:#0056b3"
            style={{ width: "85px", height: "85px" }}
          ></lord-icon>
          <p>No shopping requests found!</p>
        </div>
      ) : (
        <div className="shopping-requests-grid">
          {currentRequests.map((request) => (
            <div className="shopping-request-card" key={request.id}>
              <div className="request-header">
                <p><strong>Purchase ID:</strong> {request.Request_id}</p>
                <p><strong>Purchase Name:</strong> {request.Request_name}</p>
              </div>
              <div className="request-details">
                <p><strong>Created On:</strong> {new Date(request.createdAt.seconds * 1000).toLocaleString()}</p>
                <p><strong>Status:</strong> {request.Status || "Pending"}</p>
                {request.Order_ID && <p><strong>Order ID:</strong> {request.Order_ID}</p>}
              </div>
              <div className="request-footer">
                <button className="view-details-btn" onClick={() => handleViewDetails(request)}>
                  View Details
                </button>
              </div>
            </div>
          ))}
        </div>
      )}

           {/* Pagination Controls */}
<div className="pagination-container">
  <button
    onClick={() => setCurrentPage(currentPage - 1)}
    disabled={currentPage === 1}
    className="arrow-btn"
  >
    <i className="fas fa-arrow-left"></i> {/* Left arrow icon */}
  </button>

  {pageNumbers.map((page, index) => (
    <button
      key={index}
      className={`page-btn ${page === currentPage ? "active" : ""}`}
      onClick={() => typeof page === "number" && setCurrentPage(page)}
      disabled={page === "..."}
    >
      {page}
    </button>
  ))}

  <button
    onClick={() => setCurrentPage(currentPage + 1)}
    disabled={currentPage === totalPages}
    className="arrow-btn"
  >
    <i className="fas fa-arrow-right"></i> {/* Right arrow icon */}
  </button>
</div>

    </div>
  </div>
)}





{isShoppingRequestModalOpen && (
  <div className="modal-overlay">
    <div className="modal-details">
      <button className="close-modal" onClick={toggleShoppingRequestModal}>
        ×
      </button>
      <h2>Let us Shop for You</h2>
      <div className="form-group">
        <p className="info">Please provide a name for your package</p>
        <input
          type="text"
          value={shoppingRequestName}
          onChange={(e) => setShoppingRequestName(e.target.value)}
          placeholder="Please give a Name (eg: Nike order)"
          required
        />
      </div>

      {items.map((item, index) => (
        <div className="item-entry" key={item.id}> {/* Use item.id for unique key */}
          <p>#{index + 1}</p>
          <input
           
            type="text"
            value={item.url}
            onChange={(e) => handleItemChange(index, "url", e.target.value)}
            placeholder="please enter link of item to"
            style={{ borderRadius: '15px' }} 
          />
          <input
            type="text"
            value={item.Description}
            onChange={(e) => handleItemChange(index, "Description", e.target.value)}
            placeholder="Size, Color"
            style={{ borderRadius: '15px' }}
          />
          <button className="delete-btn" onClick={() => deleteItem(item.id)}>
          <lord-icon
            src="https://cdn.lordicon.com/hwjcdycb.json"
            trigger="hover"
            stroke="bold"
            colors="primary:#E33F31,secondary:#E33F31"
             state="hover-slide"
            style={{ width: "30px", height: "30px" }}
            >

          </lord-icon>
          </button>
          
        </div>
      ))}

      <button title="Add New Link" className="add-links" onClick={addItem}>
      <lord-icon
            src="https://cdn.lordicon.com/sbnjyzil.json"
            trigger="hover"
            stroke="bold"
            colors="primary:#0056B3,secondary:#0056B3"
            style={{ width: "40px", height: "40px" }}
            >

      </lord-icon>
        
      </button>

      <div className="terms-and-conditions">
        <input
          type="checkbox"
          checked={agreedToTerms}
          onChange={() => setAgreedToTerms(!agreedToTerms)}
        />
        <label>
          I agree with the <a href="/terms-and-conditions">terms and conditions</a>
        </label>
        <ToastContainer/>
      </div>

      <button className="request-btn" 
              onClick={handleSubmitRequestToPurchase}
              disabled={loading}>
        {loading ? "Processing..." : "Request to Purchase"}
      </button>
      {loading && (
        <div className="loader-overlay">
          <div className="loader"></div>
        </div>
      )}
    </div>
    
  </div>
)}


{isModalOpen && selectedRequest && (
  <div className="modal-overlay">
    <div className="modal-details">
      <button className="close-modal" onClick={toggleModal}>
        ×
      </button>
      <h2>Shipment Details</h2>
      <div className="modal-shipment-details-bg">
        
        <p>
          <strong>Purchase ID:</strong> {selectedRequest.Request_id}
        </p>
        <p>
          <strong>Purchase Name:</strong> {selectedRequest.Request_name}
        </p>
        <p>
          <strong>Purchase Status:</strong> {selectedRequest.Status}
        </p>
        <p>
          <strong>Service Type:</strong> {selectedRequest.Service_type}
        </p>
        <p>
          <strong>Created On:</strong>{" "}
          {selectedRequest.createdAt
            ? new Date(selectedRequest.createdAt.seconds * 1000).toLocaleString()
            : "Invalid Date"}
        </p>

        {selectedRequest.Order_ID && (
  <p>
    <strong>Order ID:</strong> {selectedRequest.Order_ID}
  </p>
)}
      </div>
      {(selectedRequest.Status === "Item Rejected" || selectedRequest.Status === "Payment Rejected") && selectedRequest.Admin_Remark && (
  <p>
    <strong>Admin Remark:</strong> {selectedRequest.Admin_Remark}
  </p>
)}


      <div className="modal-shipment-details">
        {selectedRequest.product_reference_url &&
          selectedRequest.product_reference_url.length > 0 && (
            <>
              <h3>Requested Items (URLs)</h3>
              <div className="table-container">
                
              <table className="table">
  <thead>
    <tr>
      <th>URL</th>
      <th>Description</th>
      {selectedRequest.Unit_price && selectedRequest.Unit_price.some((price) => price) && <th>Unit Price</th>}
      {selectedRequest.QTY && selectedRequest.QTY.some((qty) => qty) && <th>QTY</th>}
      {selectedRequest.Delivery_charge && selectedRequest.Delivery_charge.some((charge) => charge) && (
        <th>Delivery Charge</th>
      )}
      {selectedRequest.Tax_amount && selectedRequest.Tax_amount.some((tax) => tax) && <th>Tax Amount</th>}
      {selectedRequest.Total_price && selectedRequest.Total_price.some((total) => total) && <th>Total Price (MVR)</th>}
    </tr>
  </thead>
  <tbody>
    {selectedRequest.product_reference_url.map((url, index) => (
      <tr key={index}>
        <td>
        <a href={getUrlWithProtocol(url)} target="_blank" rel="noopener noreferrer" title="Open Product Link">
          <center>
                            <lord-icon
                              src="https://cdn.lordicon.com/gsjfryhc.json"
                              trigger="hover"
                              stroke="bold"
                              colors="primary:#0056B3,secondary:#0056B3"
                              style={{ width: "30px", height: "30px" }}
                              ></lord-icon> </center>
                            </a>
        </td>
        <td>{selectedRequest.Description[index]}</td>

        {/* Conditionally display Unit Price if not empty */}
        {selectedRequest.Unit_price && selectedRequest.Unit_price[index] !== undefined && (
          <td>{selectedRequest.Unit_price[index]}</td>
        )}

        {/* Conditionally display QTY if not empty */}
        {selectedRequest.QTY && selectedRequest.QTY[index] !== undefined && <td>{selectedRequest.QTY[index]}</td>}

        {/* Conditionally display Delivery Charge if not empty */}
        {selectedRequest.Delivery_charge && selectedRequest.Delivery_charge[index] !== undefined && (
          <td>{selectedRequest.Delivery_charge[index]}</td>
        )}

        {/* Conditionally display Tax Amount if not empty */}
        {selectedRequest.Tax_amount && selectedRequest.Tax_amount[index] !== undefined && (
          <td>{selectedRequest.Tax_amount[index]}</td>
        )}

        {/* Conditionally display Total Price if not empty */}
        {selectedRequest.Total_price && selectedRequest.Total_price[index] !== undefined && (
          <td>{selectedRequest.Total_price[index]} MVR</td>
        )}
      </tr>
    ))}
  </tbody>
</table>

{/* Calculate and display Total Price + 5% */}
{selectedRequest.Total_price && selectedRequest.Total_price.length > 0 && (
  <div className="total-price-summary">
    <p1>
      <strong>Total Amount to Pay:</strong>{' '}
      {(
        selectedRequest.Total_price.reduce((sum, price) => sum + price, 0) * 1.05
      ).toFixed(2)}{' '}
      MVR
    </p1>
    <p2>(Includes 5% extra charge)</p2>
  </div>
)}

        </div>
      </>
    )}

                {/* Conditionally render Edit button based on Service_type */}
{(selectedRequest.Service_type === "Shop online" ||
  selectedRequest.Status === "Pending" ||
  selectedRequest.Status === "Item Rejected") && (
    <button className="edit-request-btn" onClick={handleEditRequest}>
      Edit Purchase Request
    </button>
)}

              {/* Conditionally render Payment Instructions and Upload Payment Receipt for Payment for Items Pending */}
{(selectedRequest.Status === "Payment for Items Pending" || selectedRequest.Status === "Payment Rejected") && (
  <>
  <div className="icon_payment">
    <lord-icon
                      src="https://cdn.lordicon.com/ysqeagpz.json"
                      trigger="loop"
                      delay="2000"
                      stroke="bold"
                      colors="primary:#112151,secondary:#0056b3"
                      style={{ width: "50px", height: "50px" }}
                    ></lord-icon>
    <h3>Payment Instructions</h3>
    </div>
  <div className="payment-instructions-container">
    <div className="payment-instructions">
      {bankDetails.length === 0 ? (
        <p>No bank details available</p>
      ) : (
        bankDetails.map((bank, index) => (
          <p key={index}>
            <strong>Account {index + 1}:</strong> {bank.accountNumber} ({bank.currency}) -{" "}
            {bank.bankName}
          </p>
        ))
      )}
    </div>
  </div>
  <div className="upload-container">
    <h3>Upload Payment Receipt</h3>
    <div className="upload-receipt">
      <input
        type="file"
        id="paymentReceipt"
        name="paymentReceipt"
        onChange={(e) => setReceiptFile(e.target.files[0])}
      />
      
      {progress > 0 && (
        <div className="progress-bar-container">
          <div className="progress-bar-pay" style={{ width: `${progress}%` }}>
            {Math.round(progress)}%
          </div>
        </div>
      )}
     <div className="upload-btn-container">
      <button className="upload-btn" onClick={handleReceiptUpload}>
        Upload
      </button>
     </div> 
    </div>
    <ToastContainer/>
  </div>
  </>
)}

              </div>
            </div>
          </div>
        )}

        {isEditModalOpen && (
          <div className="modal-overlay">
            <div className="modal-details">
              <button className="close-modal" onClick={toggleEditModal}>
                ×
              </button>
              <h2>Edit Your Requests</h2>
              <p>Note: Editing package will revert the status.</p>
              {items.map((item, index) => (
                <div className="item-entry" key={index}>
                  <p>{index + 1}</p>
                  <input
                    type="text"
                    value={item.url}
                    onChange={(e) => handleItemChange(index, "url", e.target.value)}
                    placeholder="Item/Product URL"
                  />
                  <input
                    type="text"
                    value={item.Description}
                    onChange={(e) => handleItemChange(index, "Description", e.target.value)}
                    placeholder="Description"
                  />
                  <button className="delete-btn" title="Delete" onClick={() => deleteItem(item.id)}>
                    <lord-icon
                      src="https://cdn.lordicon.com/hwjcdycb.json"
                      trigger="hover"
                      stroke="bold"
                      colors="primary:#E33F31,secondary:#E33F31"
                      state="hover-slide"
                      style={{ width: "30px", height: "30px" }}
                    ></lord-icon>
                  </button>
                </div>
              ))}

              <div className="button-container">
                <button className="add-more" onClick={addItem} title="Add More">
                  <lord-icon
                    src="https://cdn.lordicon.com/sbnjyzil.json"
                    trigger="hover"
                    stroke="bold"
                    colors="primary:#ffffff,secondary:#ffffff"
                    state="hover-slide"
                    style={{ width: "50px", height: "50px" }}
                  ></lord-icon>
                  Add
                </button>
                <button className="request-btn" onClick={handleSaveChanges}>
                  <lord-icon
                    src="https://cdn.lordicon.com/tsrgicte.json"
                    trigger="hover"
                    stroke="bold"
                    colors="primary:#ffffff,secondary:#ffffff"
                    state="hover-slide"
                    style={{ width: "50px", height: "50px" }}
                  ></lord-icon>
                  Save
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
      
      <Footer />
      <ToastContainer/>
    </div>
  );
};

export default Dashboard;