import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider } from "firebase/auth";
import { getFirestore } from "firebase/firestore"; 
import { getStorage } from 'firebase/storage';


// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyC4QKdDbH1hPsbSW12XMOYI9QRcyRiO8E8",
  authDomain: "eparcel-maldives.firebaseapp.com",
  projectId: "eparcel-maldives",
  storageBucket: "eparcel-maldives.appspot.com",
  messagingSenderId: "477546807776",
  appId: "1:477546807776:web:ebd02e89d1839a5792ab85"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
const googleProvider = new GoogleAuthProvider();
const storage = getStorage(app);

export { app, auth, db, googleProvider, storage };




