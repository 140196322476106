import React, { useState, useEffect, useMemo } from 'react';
import './archived_shipments.css'; 
import { auth, db } from '../../firebase/Firebase'; 
import { doc, collection, query, where, getDocs, getDoc } from 'firebase/firestore';
import { useTable } from 'react-table'; // Import react-table hook
import Footer from "../../components/footer/Footer";

const Archive = () => {
  const [userData, setUserData] = useState({
    userName: '',
    shoppingRequests: [], // Array to store shopping requests
  });

  useEffect(() => {
    const fetchUserData = async () => {
      const user = auth.currentUser;
      if (user) {
        // Fetch user document using getDoc
        const userDocRef = doc(db, 'users', user.uid);
        const userSnapshot = await getDoc(userDocRef);  // Fixed getDoc usage for a single document

        if (userSnapshot.exists()) {
          const userData = userSnapshot.data();
          setUserData((prevData) => ({
            ...prevData,
            userName: userData['User Name'] || '',
          }));

          // Fetch shopping requests with status 'Complete'
          const shoppingRequestsRef = collection(db, 'Shipping_items');
          const q = query(shoppingRequestsRef, where('Status', '==', 'Complete'));
          const querySnapshot = await getDocs(q);

          const requests = querySnapshot.docs.map(doc => {
            const requestData = doc.data();
            return {
              ...requestData,
              createdAt: requestData.createdAt ? requestData.createdAt.toDate() : null, // Convert Firebase timestamp to JavaScript Date
            };
          });

          setUserData((prevData) => ({
            ...prevData,
            shoppingRequests: requests,
          }));
        } else {
          console.log('No such document!');
        }
      } else {
        // Redirect to sign-in if not authenticated
        window.location.href = '/signin';
      }
    };

    fetchUserData();
  }, []);

  // Columns definition for the table
  const columns = useMemo(() => [
    {
      Header: 'Order ID',
      accessor: 'Request_id', // accessor is the "key" in the data
    },
    {
      Header: 'Date',
      accessor: (row) => row.createdAt ? row.createdAt.toLocaleString() : 'N/A', // Format the date
    },
    {
      Header: 'Items',
      accessor: 'Request_name',
    },
    {
      Header: 'Status',
      accessor: 'Status',
    },
  ], []);

  const data = useMemo(() => userData.shoppingRequests, [userData.shoppingRequests]);

  // UseTable hook
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({ columns, data });

  return (
    <div className='archive-container-main'>
      <div className="archive-container">
        <div className="welcome-section-archived">
          <h1>Archived Packages</h1>
        </div>
  
        {/* Display shopping requests with status 'Complete' */}
        <div className='shopping-requests-section-container'>
          <div className="shopping-requests-section">
            {userData.shoppingRequests.length > 0 ? (
              <table {...getTableProps()} className="table">
                <thead>
                  <tr>
                    <th>Customer ID</th>
                    <th>Package Name</th>
                    <th>Receiver Address</th>
                    <th>Total Billable Weight</th>
                    <th>Warehouse Location</th>
                    <th>Request IDs</th>
                    <th>Date Created</th>
                  </tr>
                </thead>
                <tbody>
                  {userData.shoppingRequests.map((request) => (
                    <tr key={request.id}>
                      <td>{request.Customer_ID}</td>
                      <td>{request.packageName}</td>
                      <td>{request.receiverAddress}</td>
                      <td>{request.total_billable_weight}</td>
                      <td>{request.warehouse_location}</td>
                      <td>
                        {request.request_ids && request.request_ids.length > 0
                          ? request.request_ids.join(", ")
                          : "N/A"}
                      </td>
                      <td>
                      {request.createdAt && request.createdAt.toDate
                        ? request.createdAt.toDate().toLocaleString()
                        : 'N/A'}
                    </td>

                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <div className="info-box-archive">
                <div className="info-icon-archive">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-exclamation-circle" viewBox="0 0 16 16">
                    <path d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 1 8 0a8 8 0 0 1 0 16z"/>
                    <path d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0zm.93-7a.5.5 0 0 1 .992.09l-.447 4.005a.552.552 0 0 1-1.095 0L7.072 4.09a.5.5 0 0 1 .86-.09z"/>
                  </svg>
                </div>
                <p>No Shipment Found</p>
              </div>
            )}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
  
};

export default Archive;
