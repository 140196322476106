import React, { useState, useEffect } from 'react';
import { db } from '../../firebase/Firebase'; // Import your Firebase configuration
import { collection, updateDoc, doc, query, where, onSnapshot, orderBy, addDoc, getDocs } from 'firebase/firestore';
import './AdminPanel.css';
import Select from 'react-select';
import Footer from '../../components/footer/Footer';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const AdminPanel = () => {
  const [shoppingRequests, setShoppingRequests] = useState([]);
  const [loading, setLoading] = useState(true);
  const [userSearch, setUserSearch] = useState('');
  const [userDetails, setUserDetails] = useState(null);
  const [showPopup, setShowPopup] = useState(false);
  const [newRequest, setNewRequest] = useState({
    userId: '',
    requestName: '',
    weight: '',
    Service_type: '',
    length: '',
    width: '',
    height: '',
    volumetricWeight: '',
    status: 'Pending',
    packageType: '',
    warehouseLocation: '',
    orderId: '',
    trackingNumber: '',
  });
  const [userSuggestions, setUserSuggestions] = useState([]);
  const [selectedRequest, setSelectedRequest] = useState(null);
  const [activeTab, setActiveTab] = useState('shoppingRequests');

  const [shippingItems, setShippingItems] = useState([]);
  const [loadingShipment, setLoadingShipment] = useState(true);

  // Function to add 'https://' if it's not present
const getUrlWithProtocol = (url) => {
  // Check if the URL already starts with 'http://' or 'https://'
  if (!/^https?:\/\//i.test(url)) {
    return `https://${url}`; // Prepend 'https://'
  }
  return url;
};

  const [bankDetails, setBankDetails] = useState([]); // State for storing bank details
  const [newBankDetail, setNewBankDetail] = useState({ accountNumber: '', bankName: '', currency: '' });
   // For adding a new bank detail

  const [orderIds, setOrderIds] = useState([]); // Add this state to store the order IDs

  const fetchOrderIds = async (userId) => {
  try {
    if (userId) {
      const orderQuery = query(collection(db, 'shopping_Requests'), where('Customer_ID', '==', userId));
      const querySnapshot = await getDocs(orderQuery);

      if (!querySnapshot.empty) {
        const orders = querySnapshot.docs.map((doc) => doc.data().Order_ID).filter(orderId => !!orderId); // Extract and filter Order_IDs
        if (orders.length > 0) {
          setOrderIds(orders);
        } else {
          setOrderIds([]); // Clear if no order IDs found
        }
      } else {
        setOrderIds([]); // Clear if no matching documents found
      }
    }
  } catch (error) {
    console.error('Error fetching Order_IDs:', error);
  }
};

  

  useEffect(() => {
    const shoppingCollectionRef = collection(db, 'shopping_Requests');
    const q = query(shoppingCollectionRef, orderBy('createdAt', 'desc')); // Order by createdAt in descending order
    const unsubscribe = onSnapshot(q, (snapshot) => {
      const requests = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setShoppingRequests(requests);
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    // Fetch bank details from Firestore
    const bankCollectionRef = collection(db, 'bank_Details');
    const unsubscribe = onSnapshot(bankCollectionRef, (snapshot) => {
      const banks = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setBankDetails(banks);
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    const fetchUserSuggestions = async () => {
      const usersCollectionRef = collection(db, 'users');
      const querySnapshot = await getDocs(usersCollectionRef);
      const suggestions = querySnapshot.docs.map((doc) => ({
        value: doc.data().User_ID,
        label: doc.data().User_ID,
        userName: doc.data()['User Name'], // Fetch User Name along with the ID
      }));
      setUserSuggestions(suggestions);
    };

    fetchUserSuggestions();
  }, []);



  useEffect(() => {
    const shippingCollectionRef = collection(db, 'Shipping_items');
    const q = query(shippingCollectionRef, orderBy('createdAt', 'desc')); // Order by createdAt in descending order
    const unsubscribe = onSnapshot(q, (snapshot) => {
      const items = snapshot.docs
        .map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }))
        .filter((item) => item.Status !== "Complete"); // Filter items with Status not equal to "Complete"
  
      setShippingItems(items);
      setLoadingShipment(false);
    });
  
    // Clean up the subscription when the component is unmounted
    return () => unsubscribe();
  }, []);



  const handleUserSearch = async () => {
    if (!userSearch) return;
    const q = query(collection(db, 'users'), where('User_ID', '==', userSearch));
    onSnapshot(q, (querySnapshot) => {
      if (!querySnapshot.empty) {
        setUserDetails(querySnapshot.docs[0].data());
      } else {
        alert('User not found');
        setUserDetails(null);
      }
    });
  };

  const handleAddNewRequest = async () => {
    const {
      userId,
      weight,
      length,
      width,
      height,
      volumetricWeight,
      orderId,
      trackingNumber,
      warehouseLocation,
      packageType,
      dateCreated,
      requestName,
    } = newRequest;

        // Calculate billable weight: the greater of volumetricWeight and actual weight
        const billableWeight = Math.max(volumetricWeight, weight);
    try {
      const userQuery = query(collection(db, 'users'), where('User_ID', '==', userId));
      const userSnapshot = await getDocs(userQuery);

      if (!userSnapshot.empty) {
        const userDoc = userSnapshot.docs[0];
        const customerId = userDoc.data().User_ID;

        // Collection reference for Received Packages
        const packagesCollectionRef = collection(db, 'Received_packages');

        await addDoc(packagesCollectionRef, {
          Package_Type: packageType,
          Warehouse_Location: warehouseLocation,
          Customer_ID: customerId,
          User_Name: requestName,
          Order_ID: orderId,
          Tracking_Number: trackingNumber,
          Length: length,
          Width: width,
          Height: height,
          Volumetric_Weight: (length * width * height) / 5000, // Volumetric weight calculation
          billable_weight:billableWeight,
          Actual_Weight: weight,
          createdAt: new Date(),
        });

        // Reset form fields after successful submission
        setNewRequest({
          userId: '',
          requestName: '',
          weight: '',
          length: '',
          width: '',
          height: '',
          volumetricWeight: '',
          orderId: '',
          trackingNumber: '',
          warehouseLocation: '',
          dateCreated: '',
          packageType: '',
          status: 'Pending',
        });

       
        toast.success("Package added successfully!");
        setShowPopup(false);
      } else {
        
        toast.error("User not found. Please check the User_ID.");
      }
    } catch (error) {
      console.error("Error adding package:", error);
    }
  };

  const togglePopup = () => {
    setShowPopup(!showPopup);
  };

  // Handle user ID selection from the dropdown
  const handleUserIdChange = (selectedOption) => {
    const user = userSuggestions.find((option) => option.value === selectedOption.value);
    if (user) {
      setNewRequest((prev) => ({
        ...prev,
        userId: selectedOption.value,
        requestName: user.userName, // Set User Name automatically
      }));
      fetchOrderIds(selectedOption.value); // Fetch Order IDs based on the selected User_ID
    }
  };
  
  const handleReject = async () => {
    if (selectedRequest) {
      // Prompt the admin to enter a remark
      const adminRemark = prompt("Please enter your remark for rejecting this shipment:");

      if (!adminRemark) {
        alert("Remark is required to reject the shipment.");
        return;
      }

      const requestDocRef = doc(db, "shopping_Requests", selectedRequest.id);
      let updatedStatus = ""; // Variable to store the new status

      // Check the current status and update accordingly
      if (selectedRequest.Status === "Agent Review Ongoing" || selectedRequest.Status === "Pending") {
        updatedStatus = "Item Rejected"; // Update to "Item Reject" for Agent Review in Progress
      } else if (selectedRequest.Status === "Payment Verification Pending") {
        updatedStatus = "Payment Rejected"; // Update to "Customer Action Required" for payment pending
      } else {
        alert("Invalid status for rejection.");
        return;
      }

      try {
        // Update the status and save the admin's remark
        await updateDoc(requestDocRef, {
          Status: updatedStatus,
          Admin_Remark: adminRemark, // Add remark to the database
        });
        alert(`Request status changed to '${updatedStatus}' and remark added.`);
      } catch (error) {
        console.error("Error updating request status or adding remark:", error);
        alert("Error updating request status or adding remark. Please try again.");
      }
    }
  };

  const handleRequestClick = async (request) => {
    try {
      // If the current status is already "test", skip updating the status
      if (request.Status === "Pending") {
        // Optionally, you can check for other statuses you want to skip
        const requestDocRef = doc(db, 'shopping_Requests', request.id);

        // Update the status to "test" only if it's not already "test"
        await updateDoc(requestDocRef, { Status: "Agent Review Ongoing" });

        // Update the status in the local state
        const updatedRequests = shoppingRequests.map((r) =>
          r.id === request.id ? { ...r, Status: "Agent Review Ongoing" } : r
        );
        setShoppingRequests(updatedRequests);
      }

      // Set the selected request for the popup
      setSelectedRequest(request);

      // Show the popup
      setShowPopup(true);
    } catch (error) {
      console.error('Error updating request:', error);
    }
  };

  const handleApprove = async () => {
    if (selectedRequest) {
      const requestDocRef = doc(db, "shopping_Requests", selectedRequest.id);

      try {
        // Update the status to "Payment Request Completed"
        await updateDoc(requestDocRef, {
          Status: "Payment Request Completed",
        });
        alert("Request status changed to 'Payment Request Completed'");
      } catch (error) {
        console.error("Error updating request status:", error);
        alert("Error updating request status. Please try again.");
      }
    }
  };

  const handleOrderID = async () => {
    // Prompt the admin to enter the Order_ID
    const orderId = prompt("Please enter the Order_ID:");

    if (orderId) {
      try {
        const requestDocRef = doc(db, "shopping_Requests", selectedRequest.id);

        // Update the shopping request with the entered Order_ID and change Status to "Request Completed"
        await updateDoc(requestDocRef, {
          Order_ID: orderId,
          Status: "Request Completed"  // Change status to "Request Completed"
        });

        alert("Order_ID saved and status changed to 'Request Completed'!");
      } catch (error) {
        console.error("Error updating Order_ID or status:", error);
        alert("Error saving Order_ID or changing status. Please try again.");
      }
    } else {
      alert("Order_ID cannot be empty.");
    }
  };

  const handleClosePopup = () => {
    setSelectedRequest(null);
    setShowPopup(false);
  };

  const handleUpdateRequest = async () => {
    if (selectedRequest) {
      const requestDoc = doc(db, 'shopping_Requests', selectedRequest.id);

      // Define the static status
      const staticStatus = "Payment for Items Pending";

      // Prepare the update data
      const updateData = {
        Status: staticStatus, // Set the static status
        Weight: selectedRequest.Weight,
        Unit_price: selectedRequest.Unit_price || [],
        QTY: selectedRequest.QTY || [],
        Total_price: selectedRequest.Total_price || [],
        Delivery_charge: selectedRequest.Delivery_charge || [],
        Tax_amount: selectedRequest.Tax_amount || [],
      };

      // Only add product_reference_url if it's not undefined
      if (selectedRequest.product_reference_url) {
        updateData.product_reference_url = selectedRequest.product_reference_url;
      }

      try {
        await updateDoc(requestDoc, updateData);
        handleClosePopup(); // Close the popup after a successful update
      } catch (error) {
        console.error("Error updating request:", error);
        // Handle error (e.g., show an error message to the user)
      }
    }
  };

  // Function to handle adding new bank details
  const handleAddBankDetail = async () => {
    try {
      setLoading(true); // Start loading
      const bankCollectionRef = collection(db, 'bank_Details');
      await addDoc(bankCollectionRef, newBankDetail);
      setNewBankDetail({ accountNumber: '', bankName: '', currency: '' }); // Reset the form
      toast.success("Bank account added successfully!");
    } catch (error) {
      console.error('Error adding bank detail:', error);
      toast.error('Failed to add bank account.');
    } finally {
      setLoading(false); // Stop loading once done
    }
  };


  // Function to update item status to "Complete"
const updateItemStatus = async (itemId) => {
  try {
    const itemRef = doc(db, "Shipping_items", itemId);
    await updateDoc(itemRef, {
      Status: "Complete",
    });
    alert("Status updated to Complete");
  } catch (error) {
    console.error("Error updating status: ", error);
    alert("Failed to update status");
  }
};

  return (
  <div className='admin-panel-container-main'>
    <div className="admin-panel-container">
      <div className='heading_admin'>
        <h1>Admin Panel</h1>
    <div className='tab-buttons-container'>
      <div className="tab-buttons">
        <button
          className={activeTab === 'shoppingRequests' ? 'active' : ''}
          onClick={() => setActiveTab('shoppingRequests')}
        >
          Shopping Requests
        </button>
        <button
          className={activeTab === 'users' ? 'active' : ''}
          onClick={() => setActiveTab('users')}
        >
          Users
        </button>
        <button
          className={activeTab === 'bankDetails' ? 'active' : ''}
          onClick={() => setActiveTab('bankDetails')}
        >
          Bank Details
        </button>

        <button
          className={activeTab === 'shippingItems' ? 'active' : ''}
          onClick={() => setActiveTab('shippingItems')}
        >
          New Shipment
        </button>

        
        <button onClick={togglePopup}>Add New Packages</button>
      </div>
    </div>
    </div>

    

{activeTab === 'shippingItems' && (
  <div className="admin-section">
    <h2>Shipping Items</h2>
    {loadingShipment ? (
      <div className="loader-overlay">
        <div className="loader"></div>
      </div>
    ) : (
      <table className="admin-table">
        <thead>
          <tr>
            <th>Package ID</th>
            <th>Customer ID</th>
            <th>Package Name</th>
            <th>Receiver Address</th>
            <th>Total Billable Weight</th>
            <th>Warehouse Location</th>
            <th>Date Created</th>
            <th>Request IDs</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {shippingItems.map((item) => (
            <tr key={item.id}>
              <td>{item.id}</td>
              <td>{item.Customer_ID}</td>
              <td>{item.package_name}</td>
              <td>{item.receiver_address}</td>
              <td>{item.total_billable_weight}</td>
              <td>{item.warehouse_location}</td>
              <td>
                {item.createdAt
                  ? new Date(item.createdAt.seconds * 1000).toLocaleString()
                  : 'N/A'}
              </td>
              <td>
                {item.request_ids && item.request_ids.length > 0
                  ? item.request_ids.join(", ")
                  : "N/A"}
              </td>
              <td>
                <button className="btnConfirm"onClick={() => updateItemStatus(item.id)}>
                  Confirm
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    )}
  </div>
)}


      {activeTab === 'shoppingRequests' && (
        <div className="admin-section">
          <h2>Shopping Requests</h2>
          {loading ? (
            <div className="loader-overlay">
        <div className="loader"></div>
      </div>
          ) : (
            <table className="admin-table">
              <thead>
                <tr>
                  <th>Request ID</th>
                  <th>Request Name</th>
                  <th>Current Status</th>
                  <th>Date Created</th>

                </tr>
              </thead>
              <tbody>
                {shoppingRequests.map((request) => (
                  <tr key={request.id}>
                    <td>
                     
                      <a href="#" onClick={() => handleRequestClick(request)}>
                        {request.Request_id}
                      </a>
                   
                    </td>
                    <td>{request.Request_name}</td>
                    <td>{request.Status}</td>
                    <td>
                      {request.createdAt
                        ? new Date(request.createdAt.seconds * 1000).toLocaleString()
                        : 'N/A'}
                    </td>

                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
      )}

      {activeTab === 'bankDetails' && (
        <div className="admin-section">
          <h2>Bank Details</h2>

          {/* Add New Bank Details Form */}
          <div className="bank-details-form">
            <h3>Add New Bank Accounts</h3>
            <label>Account Number</label>
            <input
              type="text"
              placeholder="Enter your account number"
              value={newBankDetail.accountNumber}
              onChange={(e) => {
                const value = e.target.value;
                if (/^\d*$/.test(value)) { // Only allow digits
                  setNewBankDetail((prev) => ({ ...prev, accountNumber: value }));
                }
              }}
            />
            <label>Bank Name</label>
            <input
              type="text"
              placeholder='Enter bank name and branch'
              value={newBankDetail.bankName}
              onChange={(e) => setNewBankDetail((prev) => ({ ...prev, bankName: e.target.value }))}
            />
            <label>Currency</label>
            <input
                type="text"
                placeholder="Enter currency in shortened form (Eg: AED)"
                value={newBankDetail.currency}
                onChange={(e) => {
                  const value = e.target.value.toUpperCase(); // Convert input to uppercase
                  if (/^[A-Z]{0,3}$/.test(value)) { // Only allow up to 3 uppercase letters
                    setNewBankDetail((prev) => ({ ...prev, currency: value }));
                  }
                }}
            />

            <button 
                  className='updateReq_btn' 
                  style={{marginTop:'15px', marginBottom:'45px'}} 
                  onClick={handleAddBankDetail}>Add Bank Detail</button>

                  {/* Show loader while loading */}
                  {loading && (
                    <div className="loader-overlay">
                      <div className="loader"></div>
                    </div>
                  )}
                              
          </div>
          

          {/* List Existing Bank Details */}
          <h3 className='existingTitle'> Existing Accounts</h3>
          <table className="admin-table">
            <thead>
              <tr>
                <th>Account Number</th>
                <th>Bank Name</th>
                <th>Currency</th>
              </tr>
            </thead>
            <tbody>
              {bankDetails.map((bank) => (
                <tr key={bank.id}>
                  <td>{bank.accountNumber}</td>
                  <td>{bank.bankName}</td>
                  <td>{bank.currency}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}

      {activeTab === 'users' && (
        <div className="admin-section">
          <h2>Users</h2>
          <div className="user-search">
            <input
              type="text"
              placeholder="Search by User_ID"
              value={userSearch}
              onChange={(e) => setUserSearch(e.target.value)}
            />
            <button onClick={handleUserSearch}>Search</button>
          </div>

          {userDetails && (
            <div className="user-details">
              <h3>User Details</h3>
              <p>
                <strong>User ID:</strong> {userDetails.User_ID}
              </p>
              <p>
                <strong>Name:</strong> {userDetails['User Name']}
              </p>
              <p>
                <strong>Email:</strong> {userDetails.Email}
              </p>
              <p>
                <strong>Phone:</strong> {userDetails['Mobile Number']}
              </p>

              {userDetails.Address && (
                <div>
                  <p>
                    <strong>Address:</strong>
                  </p>
                  {Object.entries(userDetails.Address).map(([key, value]) => (
                    <p key={key}>
                      <strong>{key}:</strong> {value}
                    </p>
                  ))}
                </div>
              )}
            </div>
          )}
        </div>
      )}

      {showPopup && (
        <div className="popup-modal">
          <div className="popup-content">
            {selectedRequest ? (
              <>
              <button className="closeIcon" onClick={handleClosePopup}>
                  ×
              </button>       
                
                <h3>Request Details</h3>
                <label className='labeltext'>Request ID</label>
                <p className='popCO'>{selectedRequest.Request_id}</p>

                <label className='labeltext'>Request Name</label>
                <p className='popCO'>{selectedRequest.Request_name}</p>

                <label className='labeltext'>Status</label>
                <p className='popCO'>{selectedRequest.Status}</p>

                {/* Display Payment Receipt URL if available */}
                {selectedRequest.paymentReceiptURL && (
                  <div>
                    <label 
                    style={{marginTop:'30px'}}
                    className='labeltext'>Payment Receipt</label>
                    <a href={selectedRequest.paymentReceiptURL} target="_blank" rel="noopener noreferrer">
                      {selectedRequest.paymentReceiptURL}
                    </a>
                  </div>
                )}
           
                <h3>Requested Items (URLs)</h3>

                <div className="table-container">
                  <table className="table">
                    <thead>
                      <tr>
                        <th>URL</th>
                        <th>Description</th>
                        <th>Unit Price</th>
                        <th>QTY</th>
                        <th>Delivery Charge</th>
                        <th>Tax Amount</th>
                        <th>Total Price (MVR)</th>
                      </tr>
                    </thead>
                    <tbody>
                      {(selectedRequest.product_reference_url || []).map((url, index) => (
                        <tr key={index}>
                          <td>
                            <a href={getUrlWithProtocol(url)} target="_blank" rel="noopener noreferrer" title="Open Product Link">
                            <lord-icon
                              src="https://cdn.lordicon.com/gsjfryhc.json"
                              trigger="hover"
                              stroke="bold"
                              colors="primary:#0056B3,secondary:#0056B3"
                              style={{ width: "30px", height: "30px" }}
                              ></lord-icon> 
                            </a>
                          </td>
                          <td>{(selectedRequest.Description || [])[index]}</td>

                          {/* Unit Price */}
                          <td>
                            <input
                              className='inputItems'
                              type="number"
                              value={(selectedRequest.Unit_price || [])[index] || ""}
                              onChange={(e) => {
                                const updatedPrices = [...(selectedRequest.Unit_price || [])];
                                updatedPrices[index] = parseFloat(e.target.value) || 0;

                                const updatedTotalPrice = [...(selectedRequest.Total_price || [])];
                                updatedTotalPrice[index] =
                                  (updatedPrices[index] * (selectedRequest.QTY?.[index] || 0)) +
                                  (selectedRequest.Delivery_charge?.[index] || 0) +
                                  (selectedRequest.Tax_amount?.[index] || 0);

                                setSelectedRequest((prev) => ({
                                  ...prev,
                                  Unit_price: updatedPrices,
                                  Total_price: updatedTotalPrice,
                                }));
                              }}
                              placeholder="Enter price"
                              disabled={
                                selectedRequest.Status !== "Agent Review Ongoing" &&
                                selectedRequest.Status !== "Pending"
                              } // Disable if status is neither "Agent Review Ongoing" nor "Pending"
                            />
                          </td>

                          {/* QTY */}
                          <td>
                            <input
                              type="number"
                              value={(selectedRequest.QTY || [])[index] || ""}
                              onChange={(e) => {
                                const updatedQty = [...(selectedRequest.QTY || [])];
                                updatedQty[index] = parseFloat(e.target.value) || 0;

                                const updatedTotalPrice = [...(selectedRequest.Total_price || [])];
                                updatedTotalPrice[index] =
                                  (updatedQty[index] * (selectedRequest.Unit_price?.[index] || 0)) +
                                  (selectedRequest.Delivery_charge?.[index] || 0) +
                                  (selectedRequest.Tax_amount?.[index] || 0);

                                setSelectedRequest((prev) => ({
                                  ...prev,
                                  QTY: updatedQty,
                                  Total_price: updatedTotalPrice,
                                }));
                              }}
                              placeholder="Enter QTY"
                              disabled={
                                selectedRequest.Status !== "Agent Review Ongoing" &&
                                selectedRequest.Status !== "Pending"
                              } // Disable if status is neither "Agent Review Ongoing" nor "Pending"
                            />
                          </td>

                          {/* Delivery Charge */}
                          <td>
                            <input
                              type="number"
                              value={(selectedRequest.Delivery_charge || [])[index] || ""}
                              onChange={(e) => {
                                const updatedDeliveryCharge = [...(selectedRequest.Delivery_charge || [])];
                                updatedDeliveryCharge[index] = parseFloat(e.target.value) || 0;

                                const updatedTotalPrice = [...(selectedRequest.Total_price || [])];
                                updatedTotalPrice[index] =
                                  ((selectedRequest.Unit_price?.[index] || 0) * (selectedRequest.QTY?.[index] || 0)) +
                                  updatedDeliveryCharge[index] +
                                  (selectedRequest.Tax_amount?.[index] || 0);

                                setSelectedRequest((prev) => ({
                                  ...prev,
                                  Delivery_charge: updatedDeliveryCharge,
                                  Total_price: updatedTotalPrice,
                                }));
                              }}
                              placeholder="Enter Delivery Charge"
                              disabled={
                                selectedRequest.Status !== "Agent Review Ongoing" &&
                                selectedRequest.Status !== "Pending"
                              } // Disable if status is neither "Agent Review Ongoing" nor "Pending"
                            />
                          </td>

                          {/* Tax Amount */}
                          <td>
                            <input
                              type="number"
                              value={(selectedRequest.Tax_amount || [])[index] || ""}
                              onChange={(e) => {
                                const updatedTaxAmount = [...(selectedRequest.Tax_amount || [])];
                                updatedTaxAmount[index] = parseFloat(e.target.value) || 0;

                                const updatedTotalPrice = [...(selectedRequest.Total_price || [])];
                                updatedTotalPrice[index] =
                                  ((selectedRequest.Unit_price?.[index] || 0) * (selectedRequest.QTY?.[index] || 0)) +
                                  (selectedRequest.Delivery_charge?.[index] || 0) +
                                  updatedTaxAmount[index];

                                setSelectedRequest((prev) => ({
                                  ...prev,
                                  Tax_amount: updatedTaxAmount,
                                  Total_price: updatedTotalPrice,
                                }));
                              }}
                              placeholder="Enter Tax Amount"
                              disabled={
                                selectedRequest.Status !== "Agent Review Ongoing" &&
                                selectedRequest.Status !== "Pending"
                              } // Disable if status is neither "Agent Review Ongoing" nor "Pending"
                            />
                          </td>

                          {/* Total Price */}
                          <td>
                            <input
                              type="number"
                              value={(selectedRequest.Total_price || [])[index] || ""}
                              readOnly
                            />
                          </td>
                        </tr>
                      ))}
                    </tbody>

                  </table>
                </div>
                <div className='btnContainer'>
                {(selectedRequest.Status === "Agent Review Ongoing" || selectedRequest.Status === "Pending") && (
                  <button className='updateReq_btn' onClick={handleUpdateRequest}>Update Request</button>
                )}
                {(selectedRequest.Status === "Payment Confirmed" || selectedRequest.Status === "Payment Verification Pending") && (
                  <button className='approveBtn' onClick={handleApprove}>Payment Approve</button>
                )}
                {(selectedRequest.Status === "Payment Verification Pending" || selectedRequest.Status === "Agent Review Ongoing" || selectedRequest.Status === "Pending") && (
                  <button className='reject_btn' onClick={handleReject}>Reject</button>)}
                {selectedRequest.Status === "Payment Request Completed" && (<button onClick={handleOrderID}>Submit</button>)}
                <button 
                    style={{marginTop:'15px'}}
                    className='close_btn' onClick={handleClosePopup}>Close</button>
                </div>
              </>
            ) : (
              <>
              <button className="closeIcon" onClick={handleClosePopup}>
                  ×
              </button>  
                <h3>Add New Package</h3>

                {/* Package Type */}
                <label>Package Type</label>
                <select
                  value={newRequest.packageType || ''}
                  onChange={(e) => {
                    setNewRequest((prev) => ({ ...prev, packageType: e.target.value }));
                    if (e.target.value !== 'Assisted Shopping') {
                      setNewRequest((prev) => ({ ...prev, orderId: '' })); // Clear the Order ID if it's not Assisted Shopping
                    }
                  }}
                >
                  <option value="">Select Package Type</option>
                  <option value="Assisted Shopping">Assisted Shopping</option>
                  <option value="Shop Online">Shop Online</option>
                  <option value="Warehouse Drop off">Warehouse Drop off</option>
                </select>

                {/* Warehouse Location */}
                <label>Warehouse Location</label>
                <select
                  value={newRequest.warehouseLocation || ''}
                  onChange={(e) => setNewRequest((prev) => ({ ...prev, warehouseLocation: e.target.value }))}
                >
                  <option value="">Select Warehouse Location</option>
                  <option value="Australia">Australia</option>
                  <option value="Canada">Canada</option>
                  <option value="China">China</option>
                  <option value="India">India</option>
                  <option value="Japan">Japan</option>
                  <option value="Malaysia">Malaysia</option>
                  <option value="Singapore">Singapore</option>
                  <option value="UAE">UAE</option>
                  <option value="UK">UK</option>
                  <option value="USA">USA</option>
                </select>

                {/* User ID */}
                <label>User ID</label>
                <Select
                  options={userSuggestions}
                  value={userSuggestions.find((option) => option.value === newRequest.userId)}
                  onChange={handleUserIdChange} // Function to handle User_ID change
                  placeholder="Select or type User ID"
                  isSearchable
                />

               
                {/* User Name */}
                <label>User Name</label>
                <input
                  type="text"
                  value={newRequest.requestName || ''} // Automatically set the User Name based on the selected User_ID
                  onChange={(e) => setNewRequest((prev) => ({ ...prev, requestName: e.target.value }))}
                  readOnly // Make it readonly since it's auto-filled
                />

                <div>
                    <label>Order ID</label>
                    <input
                      type="text"
                      value={newRequest.orderId || ''}
                      onChange={(e) => setNewRequest((prev) => ({ ...prev, orderId: e.target.value }))}
                      placeholder="Enter Order ID"
                    />
                  </div>

              



                {/* Tracking Number */}
                <label>Tracking Number</label>
                <input
                  type="text"
                  value={newRequest.trackingNumber || ''}
                  onChange={(e) => setNewRequest((prev) => ({ ...prev, trackingNumber: e.target.value }))}
                  placeholder="Enter Tracking Number"
                />

                {/* Length */}
                <label>Length</label>
                <input
                  type="number"
                  value={newRequest.length || ''}
                  onChange={(e) => setNewRequest((prev) => ({ ...prev, length: e.target.value }))}
                  placeholder="Enter Length"
                />

                {/* Width */}
                <label>Width</label>
                <input
                  type="number"
                  value={newRequest.width || ''}
                  onChange={(e) => setNewRequest((prev) => ({ ...prev, width: e.target.value }))}
                  placeholder="Enter Width"
                />

                {/* Height */}
                <label>Height</label>
                <input
                  type="number"
                  value={newRequest.height || ''}
                  onChange={(e) => setNewRequest((prev) => ({ ...prev, height: e.target.value }))}
                  placeholder="Enter Height"
                />

                {/* Volumetric Weight */}
                <label>Volumetric Weight</label>
                <input
                  type="number"
                  value={newRequest.volumetricWeight || (newRequest.length * newRequest.width * newRequest.height) / 5000 || ''}
                  onChange={(e) => setNewRequest((prev) => ({ ...prev, volumetricWeight: e.target.value }))}
                  placeholder="Auto-calculated Volumetric Weight"
                />

                {/* Actual Weight */}
                <label>Actual Weight</label>
                <input
                  type="number"
                  value={newRequest.weight || ''}
                  onChange={(e) => setNewRequest((prev) => ({ ...prev, weight: e.target.value }))}
                  placeholder="Enter Actual Weight"
                />
                <div className='addclose_container'>
                  <button className='updateReq_btn'onClick={handleAddNewRequest}>Add Package</button>
                  <button className='close_btn' onClick={togglePopup}>Close</button>
                </div>
              </>
              
            )}
          </div>
        </div>
      )}
    </div>
    
    <ToastContainer/>
    
    </div>
  );
};

export default AdminPanel;
