import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./signin.css";
import { auth, googleProvider } from "../../firebase/Firebase"; // Firebase auth and googleProvider
import { signInWithEmailAndPassword, signInWithPopup } from "firebase/auth"; // Firebase authentication methods
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';





const SignIn = () => {
  const [email, setEmail] = useState(""); // State to store email
  const [password, setPassword] = useState(""); // State to store password
  const [error, setError] = useState(""); // State to handle error messages
  const navigate = useNavigate();

  // Handle email and password sign-in
  const handleSignIn = async (e) => {
    e.preventDefault(); // Prevent form refresh
    try {
      // Sign in using Firebase Authentication
      await signInWithEmailAndPassword(auth, email, password);
      console.log("User signed in successfully");
      navigate("/dashboard"); // Navigate to HomePage on successful sign-in
    } catch (error) {
      console.error("Error signing in:", error);
      // Handle specific error codes for better user feedback
      if (error.code === "auth/user-not-found") {
        toast.error("User not found. Please sign up.");
      } else if (error.code === "auth/wrong-password") {
        toast.error("Incorrect password.");
      } else {
        toast.error ("Failed to sign in. Check your email and password again.");
      }
    }
  };

  // Handle Google sign-in
  const handleGoogleSignIn = () => {
    signInWithPopup(auth, googleProvider)
      .then((result) => {
        console.log("Google sign-in successful:", result.user);
        navigate("/dashboard"); // Redirect to homepage on success
      })
      .catch((error) => {
        console.error("Error during Google sign-in:", error);
        setError("Failed to sign in with Google. Please try again.");
        toast.error ("Failed to sign in with Google. Please try again!.");
      
      });
  };

  return (
    <div className="signin-container">
     <div className="back-button-container" title="Back to Home">
    <img 
      src={process.env.PUBLIC_URL + '/images/backBtn.png'}  
      alt="back button" 
      className="top-right-back-button"   
      onClick={() => window.location.href = "https://fhl.ddb.myftpupload.com/"}
      
    />
  </div>
      
      <div className="signin-form">
        <img 
             src="https://fhl.ddb.myftpupload.com/wp-content/uploads/2024/09/cropped-Fits-e-Parcel_Maldives-scaled-1.webp" 
             alt="Fits e-Parcel Maldives Logo" 
             className="logo-image" 
        />

          <h2>Sign in</h2>
          
        <p>
          Don’t have an account?{" "}
          <span className="signup-link" onClick={() => navigate("/signup")}>
            Sign Up
          </span>
        </p>
        {error && <p className="error-message">{error}</p>}{" "}
        {/* Display error messages */}
        <form onSubmit={handleSignIn}>
          <div className="input-group">
            <label>Email address</label>
            <input
              type="email"
              placeholder="Enter your email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <div className="input-group">
            <label>Password</label>
            <input
              type="password"
              placeholder="Enter your password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </div>
          <div className="forgot-password">
            <a href="/Reset-password">Forgot your password?</a>
          </div>
          <button type="submit" className="signin-btn">
            Sign in
          </button>
        </form>
        <ToastContainer />
        {/* Google Sign-In Button */}
      {/* <button className="google-signin-btn" onClick={handleGoogleSignIn}>
          Sign in with Google
        </button> */}
      </div>
    
    </div>
  );
};

export default SignIn;
