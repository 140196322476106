import React, { useState } from "react";
import "./signUp.css";
import { useNavigate } from "react-router-dom";
import { db, auth } from "../../firebase/Firebase"; // Import Firebase auth and db
import { doc, setDoc } from "firebase/firestore"; // Firestore methods
import { createUserWithEmailAndPassword } from "firebase/auth"; // Firebase Authentication
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const SignUp = () => {
  const [name, setName] = useState("");
  const [contact, setContact] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordStrength, setPasswordStrength] = useState(0); // Password strength state
  const [loading, setLoading] = useState(false); // Loading state for showing loader
  const [error, setError] = useState(""); // To store any errors during sign-up
  const navigate = useNavigate();

  // Predefined address to add for every new user
  const predefinedAddress = {
    addressLine1: "Spedicon Maldives Pvt Ltd",
    addressLine2: "4 Omadu Faanu",
    addressLine3: "7th Floor Doorbell 701a,",
    addressLine4: "Haveeree Hingun, Maafanu",
    addressLine5: "20280 Male",
    country: "Republic Of Maldives",
  };

  // Function to generate a random User ID with 4 numbers and 2 characters
  const generateUserId = () => {
    const randomNumber = Math.floor(1000 + Math.random() * 9000); // Generate a 4-digit random number
    const randomChars =
      String.fromCharCode(65 + Math.floor(Math.random() * 26)) + // First random letter
      String.fromCharCode(65 + Math.floor(Math.random() * 26)); // Second random letter
    return `${randomNumber}${randomChars}`;
  };

  // Function to evaluate password strength
  const evaluatePasswordStrength = (password) => {
    let strength = 0;
    if (password.length >= 8) strength += 25; // Password length >= 8
    if (/[A-Z]/.test(password)) strength += 25; // Contains uppercase letters
    if (/[0-9]/.test(password)) strength += 25; // Contains numbers
    if (/[\W]/.test(password)) strength += 25; // Contains special characters

    setPasswordStrength(strength);
  };

  const handlePasswordChange = (e) => {
    const value = e.target.value;
    setPassword(value);
    evaluatePasswordStrength(value);
  };

  const handleSignUp = async (e) => {
    e.preventDefault();
    setLoading(true); // Start loading

    try {
      // Step 1: Create a new user in Firebase Authentication
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const user = userCredential.user; // Get the created user (Firebase UID)

      // Generate the custom User ID
      const customUserId = generateUserId();

      // Step 2: Store additional user data in Firestore with the custom User ID
      await setDoc(doc(db, "users", user.uid), {
        User_ID: customUserId, // Store custom User ID
        "User Name": name,
        "Mobile Number": contact,
        Email: email,
        Address: predefinedAddress, // Store predefined address
      });

      toast.success("Registration successful!");
      setTimeout(() => {
        setLoading(false); // Stop loading
        navigate("/signin"); // Redirect to sign-in page after successful sign-up
      }, 2000);
    } catch (error) {
      toast.error("Registration unsuccessful. Please try again!");
      setLoading(false); // Stop loading on error
    }
  };

  return (
    <div className="signup-container">
      <div className="back-button-container" title="Back to SignIn">
        <img
          src={process.env.PUBLIC_URL + "/images/backBtn.png"}
          alt="back button"
          className="top-right-back-button"
          onClick={() => navigate("/signin")}
        />
      </div>
      <div className="signup-form">
        <center>
          <img
            src="https://fhl.ddb.myftpupload.com/wp-content/uploads/2024/09/cropped-Fits-e-Parcel_Maldives-scaled-1.webp"
            alt="Fits e-Parcel Maldives Logo"
            className="logo-image"
          />
        </center>
        <h2>Sign up</h2>
        <p>
          Already have an account?{" "}
          <span className="signin-link" onClick={() => navigate("/signin")}>
            Sign In
          </span>
        </p>
        {error && <p className="error-message">{error}</p>} {/* Display error message */}
        <form onSubmit={handleSignUp}>
          <div className="input-group">
            <label>Name</label>
            <input
              type="text"
              placeholder="Enter your name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
            />
          </div>
          <div className="input-group">
            <label>Contact number</label>
            <div className="phone-input">
              <input
                type="tel"
                placeholder="Enter phone number"
                value={contact}
                onChange={(e) => setContact(e.target.value)}
                required
              />
            </div>
          </div>
          <div className="input-group">
            <label>Email address</label>
            <input
              type="email"
              placeholder="Enter your email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <div className="input-group">
            <label>Password</label>
            <input
              type="password"
              placeholder="Enter your password"
              value={password}
              onChange={handlePasswordChange}
              required
            />
            <div className="password-strength-bar">
              <div
                className={`password-strength-level level-${passwordStrength}`}
                style={{ width: `${passwordStrength}%` }}
              ></div>
            </div>
            <p className="password-strength-text">
              {passwordStrength === 100
                ? "Strong"
                : passwordStrength >= 75
                ? "Good"
                : passwordStrength >= 50
                ? "Fair"
                : "Weak"}
            </p>
          </div>
          <button type="submit" className="signup-btn" disabled={loading}>
            {loading ? "Signing up..." : "Sign Up"} {/* Button text changes based on loading */}
          </button>
        </form>
      </div>
      {loading && (
        <div className="loader-overlay">
          <div className="loader"></div>
        </div>
      )}
      <ToastContainer />
    </div>
  );
};

export default SignUp;
