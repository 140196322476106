import React, { useEffect, useState } from "react";
import "./profile.css";
import { auth, db } from "../../firebase/Firebase"; // Assuming Firebase is already set up
import { doc, getDoc } from "firebase/firestore";
import Footer from "../../components/footer/Footer";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Profile = () => {
  const [userData, setUserData] = useState(null); // State to store user data
  const [loading, setLoading] = useState(true); // State for loading indicator

  useEffect(() => {
    const fetchUserData = async () => {
      const user = auth.currentUser;
      if (user) {
        const userDocRef = doc(db, "users", user.uid); // Fetch user document based on UID
        const userSnapshot = await getDoc(userDocRef);

        if (userSnapshot.exists()) {
          setUserData(userSnapshot.data()); // Store user data
        } else {
          console.log("No user data found");
        }
      }
      setLoading(false); // Set loading to false once data is fetched
    };

    fetchUserData();
  }, []);

  // Function to copy the address text to clipboard
  const copyToClipboard = (address) => {
    navigator.clipboard
      .writeText(address)
      .then(() => {
        toast.success("Address copied to clipboard");
      })
      .catch((err) => {
        console.error("Failed to copy: ", err);
        toast.warning("Failed to copy, please try again!");
      });
  };

  if (loading) {
    return <div className="loader-overlay">
    <div className="loader"></div>
  </div> // Display a loading message while fetching data
  }

  if (!userData) {
    return <p>No user data available.</p>; // Display message if no data is available
  }

  return (
    
  <div className="main-container-parent">
    <div className="main-container">
    <div className="parent-container">
    <div className="profile-container">
    
      <div className="user-info">
        <h2>Hi, {userData["User Name"]}</h2>{" "}
        {/* Display user's name from Firestore */}
        <div className="status">  
          
          <span className="status-active">Active</span>{" "}
          {/* Hard-coded Active status */}
        </div>
     
      <div className="parent-info-item">
        <div className="info-item">
          <i className="fas fa-user-circle"></i> {/* User icon */}
          <span>User ID: </span>
          <span>{userData["User_ID"]}</span>{" "}
          {/* Assuming suite code is static in your UI */}
        </div>
        
        
        <div className="info-item">
          <i className="fas fa-envelope"></i> {/* Email icon */}
         
          <span>{userData["Email"]}</span> {/* Display user's email */}
        </div>
      </div>  

      </div>
      

      <div className="cards-container">
  {/* Australia Address */}
  <div className="address-section-profile">
    <h3><i className="fas fa-map-marker-alt"></i> Australia Address</h3>
    <div className="address-card">
      <p id="australia-address">{userData["User Name"]} ({userData["User_ID"]}) <br />
        Unit 1/110, Wedgewood Road, <br />
        Hallam, VIC 3803 <br />
        +61390163743
      </p>
      <button title="Copy to clipboard"
        onClick={() =>
          copyToClipboard(`${userData["User Name"]} (${userData["User_ID"]}), Unit 1/110, Wedgewood Road, Hallam, VIC 3803 +61390163743`)
        }
        className="copy-button">
        <lord-icon
            src="https://cdn.lordicon.com/fjvfsqea.json"
            trigger="hover"
            stroke="bold"
            colors="primary:#17315f,secondary:#17315f"
            state="hover-slide"
            style={{ width: '40px', height: '40px' }}>
          </lord-icon> 
      </button>
    </div>
  </div>
  {/* Canada Address */}
  <div className="address-section-profile">
    <h3><i className="fas fa-map-marker-alt"></i> Canada Address</h3>
    <div className="address-card">
      <p id="canada-address">{userData["User Name"]} ({userData["User_ID"]}) <br />
        310, Gaslamp lane, Markham. Ontario. L6B1L8 Canada <br />
        Hallam, VIC 3803 <br />
        +14372209225
      </p>
      <button title="Copy to clipboard"
        onClick={() =>
          copyToClipboard(`${userData["User Name"]} (${userData["User_ID"]}),
          310, Gaslamp lane, Markham. Ontario. L6B1L8 Canada
          +14372209225`)
        }
        className="copy-button">
        <lord-icon
            src="https://cdn.lordicon.com/fjvfsqea.json"
            trigger="hover"
            stroke="bold"
            colors="primary:#17315f,secondary:#17315f"
            state="hover-slide"
            style={{ width: '40px', height: '40px' }}>
          </lord-icon> 
      </button>
    </div>
  </div>
  {/* China Address */}
  <div className="address-section-profile">
    <h3><i className="fas fa-map-marker-alt"></i> China Address</h3>
    <div className="address-card">
      <p id="china-address">{userData["User Name"]} ({userData["User_ID"]}) <br />
            Room 501, Building 3, Suixin Creative Industry Center,
            <br />
            Shicha Road, Shijing Avenue, Baiyun District,
            <br />
            Guangzhou – 510430
            <br />
            +8618922361048
      </p>
      <button title="Copy to clipboard"
        onClick={() =>
          copyToClipboard(`${userData["User Name"]} (${userData["User_ID"]}),
          Room 501, Building 3, Suixin Creative Industry Center,
          Shicha Road, Shijing Avenue, Baiyun District,
          Guangzhou – 510430
          +8618922361048`)
        }
        className="copy-button">
        <lord-icon
            src="https://cdn.lordicon.com/fjvfsqea.json"
            trigger="hover"
            stroke="bold"
            colors="primary:#17315f,secondary:#17315f"
            state="hover-slide"
            style={{ width: '40px', height: '40px' }}>
          </lord-icon> 
      </button>
    </div>
  </div>
  {/* India Address */}
  <div className="address-section-profile">
    <h3><i className="fas fa-map-marker-alt"></i> India Address</h3>
    <div className="address-card">
      <p id="india-address">{userData["User Name"]} ({userData["User_ID"]}) <br />
      Fordeal Technology Pvt Ltd
            <br />
            Shop no-106 First Floor DDA CSC, Market
            <br />
            GuruHarkishan Nagar , Paschim Vihar
            <br />
            07AADCF9135B1ZW <br />
            Opp-Laxmi Narayan Mandir
            <br />
            New Delhi – 110087
            <br />
            +916203252643
            <br />
            +919570761774
      </p>
      <button title="Copy to clipboard"
        onClick={() =>
          copyToClipboard(`${userData["User Name"]} (${userData["User_ID"]}),  
          Fordeal Technology Pvt Ltd
          Shop no-106 First Floor DDA CSC, Market
          GuruHarkishan Nagar, Paschim Vihar
          07AADCF9135B1ZW 
          Opp-Laxmi Narayan Mandir
          New Delhi – 110087
          +916203252643 +919570761774`)
        }
        className="copy-button">
        
        <lord-icon
            src="https://cdn.lordicon.com/fjvfsqea.json"
            trigger="hover"
            stroke="bold"
            colors="primary:#17315f,secondary:#17315f"
            state="hover-slide"
            style={{ width: '40px', height: '40px' }}>
          </lord-icon> 
      </button>
    </div>
  </div>
  {/* Japan Address */}
  <div className="address-section-profile">
    <h3><i className="fas fa-map-marker-alt"></i> Japan Address</h3>
    <div className="address-card">
      <p id="japan-address">{userData["User Name"]} ({userData["User_ID"]}) <br />
            512-8064, Mie ken
            <br />
            Yokkaichi city
            <br />
            1634 Isaka cho
            <br />
            Japan <br />
            +819041873870
      </p>
      <button title="Copy to clipboard"
        onClick={() =>
          copyToClipboard(`${userData["User Name"]} (${userData["User_ID"]}),
          512-8064, Mie ken
          Yokkaichi city 1634 Isaka cho
          Japan
          +819041873870`)
        }
        className="copy-button">
        <lord-icon
            src="https://cdn.lordicon.com/fjvfsqea.json"
            trigger="hover"
            stroke="bold"
            colors="primary:#17315f,secondary:#17315f"
            state="hover-slide"
            style={{ width: '40px', height: '40px' }}>
          </lord-icon> 
      </button>
    </div>
  </div>
  {/* Malaysia Address */}
  <div className="address-section-profile">
    <h3><i className="fas fa-map-marker-alt"></i> Malaysia Address</h3>
    <div className="address-card">
      <p id="malaysia-address">{userData["User Name"]} ({userData["User_ID"]}) <br />
            71, Adiva court, Desa Park City,
            <br />
            Kuala Lumpur, Malaysia
            <br />
            +60177358016
      </p>
      <button title="Copy to clipboard"
        onClick={() =>
          copyToClipboard(`${userData["User Name"]} (${userData["User_ID"]}),
          71, Adiva court, Desa Park City,
          Kuala Lumpur, Malaysia
          +60177358016`)
        }
        className="copy-button">
        <lord-icon
            src="https://cdn.lordicon.com/fjvfsqea.json"
            trigger="hover"
            stroke="bold"
            colors="primary:#17315f,secondary:#17315f"
            state="hover-slide"
            style={{ width: '40px', height: '40px' }}>
          </lord-icon> 
      </button>
    </div>
  </div>
  {/* Singapore Address */}
  <div className="address-section-profile">
    <h3><i className="fas fa-map-marker-alt"></i> Singapore Address</h3>
    <div className="address-card">
      <p id="singapore-address">{userData["User Name"]} ({userData["User_ID"]})
            <br />
            Triple-W Cargo Pte Ltd
            <br />
            No. 2, Kallang Pudding Road,
            <br />
            Office #08-08, Warehouse #01-10
            <br />
            Singapore 349307
            <br />
            +65-93668384
      </p>
      <button title="Copy to clipboard"
        onClick={() =>
          copyToClipboard(`${userData["User Name"]} (${userData["User_ID"]}),
          Triple-W Cargo Pte Ltd
          No. 2, Kallang Pudding Road,
          Office #08-08, Warehouse #01-10
          Singapore 349307
          +65-93668384`)
        }
        className="copy-button">
        <lord-icon
            src="https://cdn.lordicon.com/fjvfsqea.json"
            trigger="hover"
            stroke="bold"
            colors="primary:#17315f,secondary:#17315f"
            state="hover-slide"
            style={{ width: '40px', height: '40px' }}>
          </lord-icon> 
      </button>
    </div>
  </div>
   {/* UAE Address */}
   <div className="address-section-profile">
    <h3><i className="fas fa-map-marker-alt"></i> UAE Address</h3>
    <div className="address-card">
      <p id="uae-address">{userData["User Name"]} ({userData["User_ID"]})
            <br />
            Fits Express Parcels Delivery L.L.C
            <br />
            Warehouse # 4, Ismail Shaban Warehouse Complex,
            <br />
            Opp. Al Jazib Packaging, Al Qusais Ind Area 3 Dubai, UAE
            <br />
            +971564038721
      </p>
      <button title="Copy to clipboard"
        onClick={() =>
          copyToClipboard(`${userData["User Name"]} (${userData["User_ID"]}),
          Fits Express Parcels Delivery L.L.C
          Warehouse # 4, Ismail Shaban Warehouse Complex,
          Opp. Al Jazib Packaging, Al Qusais Ind Area 3 Dubai, UAE
          +971564038721`)
        }
        className="copy-button">
        <lord-icon
            src="https://cdn.lordicon.com/fjvfsqea.json"
            trigger="hover"
            stroke="bold"
            colors="primary:#17315f,secondary:#17315f"
            state="hover-slide"
            style={{ width: '40px', height: '40px' }}>
          </lord-icon> 
      </button>
    </div>
  </div>
  {/*UK Address */}
  <div className="address-section-profile">
    <h3><i className="fas fa-map-marker-alt"></i> UK Address</h3>
    <div className="address-card">
      <p id="uk-address">{userData["User Name"]} ({userData["User_ID"]})
            <br />
            36, Stanbury Avenue,
            <br />
            Watford, Herts,
            <br />
            WD17 3HW
            <br />
            +447716441953
      </p>
      <button title="Copy to clipboard"
        onClick={() =>
          copyToClipboard(`${userData["User Name"]} (${userData["User_ID"]}),
          36, Stanbury Avenue,
          Watford, Herts,
          WD17 3HW
          +447716441953`)
        }
        className="copy-button">
        <lord-icon
            src="https://cdn.lordicon.com/fjvfsqea.json"
            trigger="hover"
            stroke="bold"
            colors="primary:#17315f,secondary:#17315f"
            state="hover-slide"
            style={{ width: '40px', height: '40px' }}>
          </lord-icon> 
      </button>
    </div>
  </div>
  {/*USA Address */}
  <div className="address-section-profile">
    <h3><i className="fas fa-map-marker-alt"></i> USA Address</h3>
    <div className="address-card">
      <p id="usa-address">{userData["User Name"]} ({userData["User_ID"]})
            <br />
            753, Integrity Drive,
            <br />
            Lititz, PA 17543
            <br />
            +17178758149
      </p>
      <button title="Copy to clipboard"
        onClick={() =>
          copyToClipboard(`${userData["User Name"]} (${userData["User_ID"]}),
          753, Integrity Drive,
          Lititz, PA 17543
          +17178758149`)
        }
        className="copy-button">
        <lord-icon
            src="https://cdn.lordicon.com/fjvfsqea.json"
            trigger="hover"
            stroke="bold"
            colors="primary:#17315f,secondary:#17315f"
            state="hover-slide"
            style={{ width: '40px', height: '40px' }}>
          </lord-icon> 
      </button>
    </div>
  </div>
  </div>
    </div>
    
    </div>
    
    </div>
    <Footer/>
    <ToastContainer/>
</div>
    
  );
};

export default Profile;
