import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, useNavigate, useLocation } from 'react-router-dom';
import { auth } from './firebase/Firebase'; 
import SignIn from './pages/signin_page/signin';
import SignUp from './pages/SignUp/SignUp';
import Home from './pages/Home_page/HomePage';
import Dashboard from './pages/Dashboard_page/Dashboard';
import Header from './components/Header/Header';
import Profile from './pages/Profile_page/Profile';
import CheckRates from './pages/Check_Rates_Page/Check_rates';
import Archive from './pages/Archived_Shipments_Page/Archived_Shipments';
import AdminPanel from './pages/Admin_Page/AdminPanel'; 
import ResetPassword from './pages/Reset_password_Page/Reset_password';
import './index.css'; 
import Address from './pages/Address_Book_Page/address';
import './App.css';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';



function Layout({ children }) {
  const location = useLocation();

  // Hide the Header on certain routes
  const hideHeaderRoutes = ['/signin', '/signup'];

  // Check if the current route is the admin page
  const isAdminPage = location.pathname === '/admin';

  return (
    <>
      {!hideHeaderRoutes.includes(location.pathname) && <Header isAdmin={isAdminPage} />}
      {children}
    </>
  );
}


// AdminProtectedRoute component that only allows access if the user is an admin
function AdminProtectedRoute({ children }) {
  const [user, setUser] = useState(null);
  const [isAdmin, setIsAdmin] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        setUser(user); 
        const adminEmails = ['admin@example.com']; // Replace with your admin email(s)
        if (adminEmails.includes(user.email)) {
          setIsAdmin(true); // Allow access to admin panel
        } else {
          navigate('/'); // Redirect non-admin users to home page
        }
      } else {
        navigate('/signin'); // Redirect to sign-in page if not authenticated
      }
    });

    return () => unsubscribe();
  }, [navigate]);

  if (!user || !isAdmin) {
    return null; // Render nothing (or a spinner) while checking admin status
  }

  return children;
}

// ProtectedRoute component for regular users
function ProtectedRoute({ children }) {
  const [user, setUser] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        setUser(user); // User is authenticated
        const adminEmails = ['admin@example.com']; // Admin email check
        if (adminEmails.includes(user.email)) {
          navigate('/admin'); // Redirect admin to admin panel
        }
      } else {
        setUser(null);
        navigate('/signin'); // Redirect to sign-in page if not authenticated
      }
    });

    return () => unsubscribe();
  }, [navigate]);

  if (!user) {
    return null; // Render nothing (or a spinner) while checking authentication
  }

  return children;
}

function App() {
  return (
    <Router>
      <Routes>
        {/* Publicly Accessible Routes */}
        <Route
          path="/home"
          element={
            <Layout>
              <Home /> {/* Home page accessible to all users */}
            </Layout>
          }
        />
        <Route path="/signin" element={<SignIn />} />
        <Route path="/signup" element={<SignUp />} />
        <Route path="/check-rates" element={<CheckRates />} />
        <Route path="/Reset-password" element={<ResetPassword/>} />
       


        {/* Protected Routes (requires user to be signed in) */}
        <Route
          path="/dashboard"
          element={
            <ProtectedRoute>
              <Layout>
                <Dashboard />
              </Layout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/profile"
          element={
            <ProtectedRoute>
              <Layout>
                <Profile />
              </Layout>
            </ProtectedRoute>
          }
        />

        <Route
          path="/address-book"
          element={
            <ProtectedRoute>
              <Layout>
                <Address />
              </Layout>
            </ProtectedRoute>
          }
        />

        <Route
          path="/archived-shipments"
          element={
            <ProtectedRoute>
              <Layout>
                <Archive />
              </Layout>
            </ProtectedRoute>
          }
        />

        {/* Admin Panel Protected Route */}
        <Route
          path="/admin"
          element={
            <AdminProtectedRoute>
              <Layout>
                <AdminPanel />
              </Layout>
            </AdminProtectedRoute>
          }
        />

        {/* Default Route */}
        <Route path="/" element={<SignIn/>} /> {/* Default to Home page for normal users */}
      </Routes>
      <ToastContainer/>
    </Router>
  );
}

export default App;
